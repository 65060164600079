import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom"; // Import useLocation
import R from "ramda";
import { attemptLogout } from "_store/thunks/auth";
import FeedbackModal from "../FeedbackModal";

const NavBar = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const location = useLocation();

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const toggleFeedbackModal = () => setIsFeedbackModalVisible(!isFeedbackModalVisible);

  const logout = () => {
    dispatch(attemptLogout()).catch(R.identity);
  };

  const getLabelFromPath = (pathname) => {
    const firstSegment = pathname.split("/")[1];
    if (!firstSegment) return "Home";
    const words = firstSegment.replace(/_/g, " ").split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  };

  const currentLabel = getLabelFromPath(location.pathname);

  return (
    <div className="dashboard-nav-main-container">
      <FeedbackModal
        active={showFeedbackModal}
        pageName={currentLabel}
        user={user}
        onClose={() => setShowFeedbackModal(false)}
      />
      <div className="container-default w-container">
        <div className="dashboard-nav-container">
          <div className="dashboard-nav-left-content">
            <div className="mg-right-24px">
              <div className="text-400 medium color-neutral-800">
                {currentLabel}
              </div>
            </div>
          </div>
          <div className="dashboard-nav-right-content" style={{ marginRight: '20px' }}>
            <div
              className="dropdown-wrapper w-dropdown"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {!showFeedbackModal && <div className="dropdown-toggle w-dropdown-toggle">
                {/* <img
                  src={user.profilePic}
                  loading="eager"
                  alt="User profile"
                  className="avatar-circle _02 mg-right-8px"
                /> */}
                <div className="hidden-on-mbl">
                  <div className="mg-bottom-4px">
                    <div className="text-200 medium color-neutral-800">
                      {user.firstName} {user.lastName}
                    </div>
                  </div>
                  <div className="text-200 medium color-neutral-600">
                    Account settings
                  </div>
                </div>
                <div className="line-rounded-icon dropdown-arrow profile-dropdown">
                  
                </div>
              </div>}
              {isHovered && (
                <nav
                  className="dropdown-column-wrapper dashboard-nav-profile w-dropdown-list"
                  style={{
                    display: "block",
                    opacity: 1,
                    width: "202px",
                    height: "165px",
                  }}
                >
                  <div>
                    <div>
                      <a
                        href="../settings"
                        className="dashboard-profile-dropdown-link-wrapper w-inline-block"
                      >
                        <div className="dashly-custom-icon dashboard-nav-profile-icon">
                          
                        </div>
                        <div className="text-200 medium">Settings</div>
                      </a>
                      <div className="divider _20px"></div>
                      <a
                        href="mailto:support@example.com"
                        className="dashboard-profile-dropdown-link-wrapper w-inline-block"
                      >
                        <div className="dashly-custom-icon dashboard-nav-profile-icon">
                          
                        </div>
                        <div className="text-200 medium">Support</div>
                      </a>
                      <a
                        onClick={logout}
                        className="dashboard-profile-dropdown-link-wrapper transition-none w-inline-block"
                      >
                        <div className="dashly-custom-icon dashboard-nav-profile-icon color-secondary-8">
                          
                        </div>
                        <div className="text-200 medium color-secondary-8">
                          Log out
                        </div>
                      </a>
                    </div>
                  </div>
                </nav>
              )}
            </div>
          </div>
          <button className="btn-primary" style={{ width: "240px" }} onClick={() => setShowFeedbackModal(true)}>Send Feedback</button>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
