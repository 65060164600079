import React, { useEffect, useState } from "react";

import R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { push } from "redux-first-history";
import { Store as RNC } from "react-notifications-component";
import { importCandidates, updateATSCandidates } from "../../../api/integrateATS";
import { setJobShortlistStage } from "../../../api/job";
import SetShortlistStageModal from "./SetShortlistStageModal";

import { attemptGetCandidates } from "_store/thunks/candidates";
import CandidateTable from "./CandidateTable";
import NoRecordsFound from "../../elements/NoRecordsFound";
import LoadingScreen from "../../library/LoadingScreen";
import Selector from "../../elements/Selector/Selector";
import AddCandidateOptionsModal from "./AddCandidateOptionsModal";

const CandidatePage = () => {
  const dispatch = useDispatch();
  const { jobID } = useParams();
  const user = useSelector((state) => state.user);
  const candidates = useSelector((state) => state.candidates.candidatesList);
  const roleTitle = useSelector((state) => state.candidates?.job?.title);
  const job = useSelector((state) => state.candidates?.job);
  const skills = useSelector((state) => state.candidates?.job?.skills || []);

  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({ stage: "" });
  const [showModal, setShowModal] = useState(false);
  const [showShortlistModal, setShowShortlistModal] = useState(false);
  const [shortlistStage, setShortlistStage] = useState(job.shortlistStage);

  let jobStagesMap = new Map(); // Define it with a wider scope
  let initialSelectedStage = ""; // Also define this outside so it's accessible later

  if (job.externalId) {
    // console.log(job.jobStageNumtoInfo, typeof job.jobStageNumtoInfo)
    jobStagesMap = new Map(Object.entries(job.jobStageNumtoInfo));
    initialSelectedStage = `0: ${jobStagesMap.get("0")[1]}` // This now works as intended
    // console.log("\n\n\n Handling \n\n\n")
  }

  useEffect(() => {
    if (job.shortlistStage) {
      const currShortlist = jobStagesMap.get(job.shortlistStage.toString())[1];
      setShowShortlistModal(false);
      setShortlistStage(currShortlist);
    } else {
      setShowShortlistModal(true);
    }
  }, [job]);

  const handleSaveShortlistStage = (selectedStage) => {
    const parts = selectedStage.split(':'); // Split the string into parts
    const stageKey = parseInt(parts[0], 10); // Parse the first part as integer
    const stageText = parts[1].trim(); // Get the second part as text, trimming any leading/trailing spaces

    setJobShortlistStage([jobID, stageKey]);
    setShortlistStage(stageText); // Pass the isolated text part into setShortlistStage
  };

  const ratingOption = [
    { value: "", label: "None" },
    { value: "junior", label: "Junior(1-2 years)" },
    { value: "mid-level", label: "Mid Level (3-5 years)" },
    { value: "senior", label: "Senior (5+ years)" },
  ];

  const stageOptions = [
    { value: "", label: "All" },
    { value: "Shortlist", label: "Shortlisted" },
    { value: "Prospect", label: "Prospect" },
    { value: "Inactive", label: "Inactive" },
  ];

  useEffect(() => {
    if (R.isEmpty(user)) {
      dispatch(push("/login"));
    } else {
      fetchCandidates();
    }
  }, [dispatch, user, filters]); // Re-fetch candidates whenever filters change

  const fetchCandidates = () => {
    setLoading(true);
    // Filter skills to include only those with a selected rating
    const filteredSkills = skills.filter((skill) => filters[skill.skill]);
    const skillQuery = filteredSkills.map((skill) => skill.skill).join(",");
    const ratingQuery = filteredSkills
      .map((skill) => filters[skill.skill])
      .join(",");

    let queryStringParts = [];
    if (skillQuery && ratingQuery) {
      queryStringParts.push(`skill=${skillQuery}`);
      queryStringParts.push(`rating=${ratingQuery}`);
    }

    // Append the stage of candidate to the query string if it's been set
    if (filters.stage !== undefined && filters.stage !== "") {
      queryStringParts.push(`stage=${filters.stage}`);
    }

    // Join all parts of the query string
    const queryString = queryStringParts.join("&");

    dispatch(attemptGetCandidates(jobID, queryString))
      .catch(R.identity)
      .finally(() => setLoading(false));
  };

  const handleAddCandidateButton = () => {
    if (job.externalService) {
      setShowModal(true);
    } else {
      handleAddCandidate();
    }
  };

  const handleAddCandidate = () => {
    dispatch(push(`/candidate/add/${jobID}`));
  };

  const handleImportCandidates = () => {
    importCandidates({
      serviceProviderId: job.externalService,
      externalId: job.externalId,
      jobId: jobID,
    })
      .then(() => {
        RNC.addNotification({
          title: "Success!",
          message: "Started to import candidates",
          type: "success",
          insert: "bottom",
          container: "top-right",
          animationIn: ["animated", "fadeInRight"],
          animationOut: ["animated", "fadeOutRight"],
          dismiss: {
            duration: 5000,
          },
        });
        dispatch(push("/roles"));
      })
      .catch((error) => {
        RNC.addNotification({
          title: "Integration failed!",
          message: "Please reach out to customer support",
          type: "danger",
          insert: "bottom",
          container: "top-right",
          animationIn: ["animated", "fadeInRight"],
          animationOut: ["animated", "fadeOutRight"],
          dismiss: {
            duration: 5000,
          },
        });
      });
  };

  const handleUpdateATSCandidates = () => {
    // Assuming you have a way to identify which candidates are shortlisted
    // This could be a filter on your current candidate state, or a separate piece of state tracking shortlisted IDs
    updateATSCandidates({
      serviceProviderId: job.externalService,
      externalId: job.externalId,
      jobId: jobID,
    })
      .then(() => {
        RNC.addNotification({
          title: "Success!",
          message: "Candidates successfully updated in ATS",
          type: "success",
          insert: "bottom",
          container: "top-right",
          animationIn: ["animated", "fadeInRight"],
          animationOut: ["animated", "fadeOutRight"],
          dismiss: {
            duration: 5000,
          },
        });
      })
      .catch((error) => {
        RNC.addNotification({
          title: "Update failed!",
          message: "There was an issue updating candidates in the ATS",
          type: "danger",
          insert: "bottom",
          container: "top-right",
          animationIn: ["animated", "fadeInRight"],
          animationOut: ["animated", "fadeOutRight"],
          dismiss: {
            duration: 5000,
          },
        });
      });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSelectRating = (skill, rating) => {
    setFilters((prevFilters) => ({ ...prevFilters, [skill]: rating }));
  };

  const handleSelectStage = (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      stage: value,
    }));
  };

  if (loading) {
    return <LoadingScreen description={"Loading role"} />;
  }

  return (
    <div className="container-default w-container">
      {showModal && (
        <AddCandidateOptionsModal
          onClose={closeModal}
          handleImportCandidates={handleImportCandidates}
          handleAddCandidate={handleAddCandidate}
        />
      )}
      {job.externalId && showShortlistModal && (
        <SetShortlistStageModal
          active={showShortlistModal}
          stages={jobStagesMap}
          initialStage={initialSelectedStage}
          onSave={handleSaveShortlistStage}
          onClose={() => setShowShortlistModal(false)}
        />
      )}
      <div className="mg-bottom-40px">
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}> {/* Adjusted for flexible layout */}
          <div style={{ flex: '2' }}>
            <h1 className="heading-h4-size mg-bottom-8px">Candidates</h1>
            <div className="text-400">
              <b>Role:</b> {roleTitle}
            </div>
          </div>
          <div style={{ flex: !job.externalId ? '2' : '3', display: 'flex', gap: '10px' }}>
            <a
              className="btn-primary w-button"
              onClick={handleAddCandidateButton}
              style={{ flex: '1' }
              } /* Makes button take equal width */
            >
              Add Candidate
            </a>
            {job.externalId && <a
              className="btn-primary w-button"
              onClick={() => handleUpdateATSCandidates()}
              style={{ flex: '1' }} /* Makes button take equal width */
            >
              Push Updates to ATS
            </a>}
          </div>
        </div>
      </div >
      {
        candidates.length == 0 && Object.keys(filters).length === 0 ? (
          <NoRecordsFound
            title={"No candidates found"}
            subTitle={"Please click add candidate to get started"}
          />
        ) : (
          <>
            <div className="flex justify-end mg-bottom-10px">
              <div className="mg-right-10px">
                <label className="text-200 mg-bottom-10px color-neutral-700">
                  Hiring Stage
                </label>
                <Selector
                  selectedValue={filters.stage || ""}
                  onSelectValue={(value) => handleSelectStage(value)}
                  options={stageOptions}
                  className="dropdown-wrapper module-dropdown w-dropdown"
                />
              </div>
              {skills.map((skill, index) => (
                <div key={index} className="mg-right-10px">
                  <label className="text-200 mg-bottom-10px color-neutral-700">
                    {skill.skill}
                  </label>
                  <Selector
                    key={skill.skill}
                    selectedValue={filters[skill.skill] || ""}
                    onSelectValue={(rating) =>
                      handleSelectRating(skill.skill, rating)
                    }
                    options={ratingOption}
                    className="dropdown-wrapper module-dropdown w-dropdown"
                  />
                </div>
              ))}
            </div>
            <CandidateTable jobID={jobID} shortlistStage={shortlistStage} />
          </>
        )
      }
    </div >
  );
};

export default CandidatePage;
