import React, { useState, useRef, useEffect } from "react";
import { AudioVisualizer } from "ts-audio-visualizer";

import QuestionComponent from "./QuestionComponent";
import CodingExercisePage from "../CodingExercise/CodingExercisePage";
import ThankYouPage from "./ThankYouPage";
import ProctorModal from "./ProctorModal";
import {
  uploadAnswer,
  uploadCodingChallengeAnswer,
  setInterviewComplete,
} from "_api/interview";
import { uploadToS3 } from "_api/aws";

import logo from "../../../images/techscreener-logo-v2.svg";
import Timer from "../../elements/Timer/Timer";
import LoadingScreen from "../../library/LoadingScreen";

const InterviewPage = ({
  interviewQuestions,
  interviewId,
  codingChallengeId,
  hasCodingChallenge,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [screenRecording, setScreenRecording] = useState(false);
  const [webcamRecording, setWebcamRecording] = useState(false);
  const [voiceRecording, setVoiceRecording] = useState(false);
  const [screenBlobUrl, setScreenBlobUrl] = useState(null);
  const [webcamBlobUrl, setWebcamBlobUrl] = useState(null);
  const [voiceBlobUrl, setVoiceBlobUrl] = useState(null);
  const screenRecorderRef = useRef(null);
  const webcamRecorderRef = useRef(null);
  const voiceRecorderRef = useRef(null);
  const [showModal, setShowModal] = useState(true);
  const [showCodingChallenge, setShowCodingChallenge] = useState(false);
  const [showThankYouPage, setShowThankYouPage] = useState(false);
  const [questionStartTime, setQuestionStartTime] = useState(new Date());
  const [timer, setTimer] = useState(0);
  const [timerActive, setTimerActive] = useState(true);
  // Timer ref to clear interval
  const timerRef = useRef();

  const handleDataAvailable = (recorderType, event) => {
    if (event.data && event.data.size > 0) {
      const file = new File([event.data], `recording.${recorderType}`, {
        type: event.data.type,
      });
      if (recorderType === "screen") {
        setScreenBlobUrl(file);
      } else if (recorderType === "webcam") {
        setWebcamBlobUrl(file);
      } else if (recorderType === "voice") {
        setVoiceBlobUrl(file);
      }
    }
  };

  useEffect(() => {
    // Automatically close the modal when both screen and webcam recording start
    if (screenRecording && webcamRecording) {
      closeModal();
    }
  }, [screenRecording, webcamRecording]);

  const startRecording = async (recorderType) => {
    let stream;
    let mimeType;

    if (recorderType === "screen") {
      stream = await navigator.mediaDevices.getDisplayMedia({ video: true });
      mimeType = "video/webm"; // Specify the MIME type for screen recording
    } else if (recorderType === "webcam") {
      stream = await navigator.mediaDevices.getUserMedia({ video: true });
      mimeType = "video/webm"; // Specify the MIME type for webcam recording
    } else if (recorderType === "voice") {
      stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mimeType = "audio/wav"; // Specify the MIME type for voice recording
    }

    const recorderOptions = MediaRecorder.isTypeSupported(mimeType)
      ? { mimeType }
      : {};
    const recorder = new MediaRecorder(stream, recorderOptions);
    recorder.ondataavailable = (e) => handleDataAvailable(recorderType, e);
    recorder.start();

    if (recorderType === "screen") {
      screenRecorderRef.current = recorder;
      setScreenRecording(true);
    } else if (recorderType === "webcam") {
      webcamRecorderRef.current = recorder;
      setWebcamRecording(true);
    } else if (recorderType === "voice") {
      voiceRecorderRef.current = recorder;
      setVoiceRecording(true);
    }
  };

  const stopRecording = (recorderType) => {
    let recorder;
    if (recorderType === "screen") {
      recorder = screenRecorderRef.current;
      setScreenRecording(false);
    } else if (recorderType === "webcam") {
      recorder = webcamRecorderRef.current;
      setWebcamRecording(false);
    } else if (recorderType === "voice") {
      recorder = voiceRecorderRef.current;
      setVoiceRecording(false);
    }

    if (recorder) {
      recorder.stop();
      recorder.stream.getTracks().forEach((track) => track.stop());
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const startScreenAndWebcamRecording = () => {
    startRecording("webcam");
    startRecording("screen");
  };

  const stopScreenAndWebcamRecording = () => {
    stopRecording("webcam");
    stopRecording("screen");
  };

  useEffect(() => {
    if (voiceBlobUrl) {
      setIsLoading(true);
      uploadToS3({
        filename: `answer${currentQuestionIndex}.wav`,
        contentType: "audio/wav",
        blob: voiceBlobUrl,
        interviewId: interviewId,
      }).catch((error) => {
        console.error("Error uploading ", error);
      });

      const timeToAnswer = questionStartTime
        ? (new Date() - questionStartTime) / 1000
        : null;
      uploadAnswer({
        id: interviewId,
        timeToAnswerInSeconds: timeToAnswer,
        questionIdx: currentQuestionIndex,
      }).catch((error) => {
        console.error("Error updatingAnswer:", error);
      });
      setTimeout(() => {
        handleNextQuestion();
        setIsLoading(false);
      }, 2000);
    }
  }, [voiceBlobUrl]);

  useEffect(() => {
    if (screenBlobUrl) {
      uploadToS3({
        filename: `screenRecording${interviewId}.webm`,
        contentType: "video/webm",
        blob: screenBlobUrl,
        interviewId: interviewId,
      }).catch((error) => {
        console.error("Error uploading ", error);
      });
    }
  }, [screenBlobUrl]);

  useEffect(() => {
    if (webcamBlobUrl) {
      uploadToS3({
        filename: `webcamRecording${interviewId}.webm`,
        contentType: "video/webm",
        blob: webcamBlobUrl,
        interviewId: interviewId,
      }).catch((error) => {
        console.error("Error uploading ", error);
      });
    }
  }, [webcamBlobUrl]);

  const handleSubmit = (
    code,
    codingChallengeLanguage,
    timeTakenInSeconds,
    timeExpired
  ) => {
    uploadCodingChallengeAnswer({
      id: interviewId,
      timeToAnswerCodingChallengeInSeconds: timeTakenInSeconds,
      codingChallengeAnswer: code,
      codingChallengeLanguage: codingChallengeLanguage,
      codingChallengeTimeExpired: timeExpired,
    }).catch((error) => {
      console.error("Error uploading coding challenge answer:", error);
    });
    handleNextQuestion();
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < interviewQuestions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setQuestionStartTime(new Date());
      resetAndStartTimer(); // Reset and start the timer for the new question
    } else if (hasCodingChallenge && !showCodingChallenge) {
      // Show coding challenge if it exists and hasn't been shown yet
      setShowCodingChallenge(true);
      setTimerActive(false); // Optionally stop the timer if the coding challenge does not need a timer
    } else {
      setInterviewComplete({ id: interviewId });
      stopScreenAndWebcamRecording();
      setShowThankYouPage(true);
      setShowCodingChallenge(false);
      setTimerActive(false);
    }
  };

  const resetAndStartTimer = () => {
    setTimer(0);
    setTimerActive(true);
  };

  useEffect(() => {
    let interval = null;
    if (timerActive && !showModal) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timerActive, showModal]);

  const progressPercentage =
    ((currentQuestionIndex + 1) / interviewQuestions.length) * 100;

  return (
    <>
      {isLoading ? (
        <LoadingScreen description={"Uploading your response"} />
      ) : (
        <div>
          {showModal && (
            <ProctorModal
              onClose={closeModal}
              onShareScreenAndWebcam={() => startScreenAndWebcamRecording()}
              isScreenSharing={screenRecording}
              isWebcamSharing={webcamRecording}
            />
          )}
          <div className="top-bar-simple">
            <div className="container-default w-container">
              <div className="flex-horizontal">
                <a className="top-bar-simple-logo w-inline-block">
                  <img src={logo} loading="eager" />
                </a>
              </div>
            </div>
          </div>
          {!showCodingChallenge && !showThankYouPage && (
            <div className="dashboard-main-content utility-page">
              <div className="container-default w-container">
                <div className="module utility-page-form position-relative">
                  {timerActive && (
                    <Timer
                      timeLeft={`${Math.floor(timer / 60)}:
                      ${(timer % 60).toLocaleString("en-US", {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      })}`}
                    />
                  )}
                  <div className="inner-container _444px text-center">
                    <div
                      className="flex align-center gap-column-12px"
                      style={{ marginBottom: "15px", marginTop: "20px" }}
                    >
                      <div className="progress-bar">
                        <div
                          className="inner-progress-bar"
                          style={{ width: `${progressPercentage}%` }}
                        ></div>
                      </div>
                      <div className="text-100 medium color-neutral-500">
                        {currentQuestionIndex + 1}/{interviewQuestions.length}
                      </div>
                    </div>
                    <QuestionComponent
                      question={
                        interviewQuestions[currentQuestionIndex]?.question
                      }
                    />
                    {voiceRecording ? (
                      <AudioVisualizer
                        mode="wave"
                        height="100px"
                        width="100%"
                        bgColor="#252525"
                        barColor="#1476ff"
                      />
                    ) : (
                      <></>
                    )}
                    <div className="form min-h-116px w-form">
                      <button
                        className="btn-primary width-100 w-button"
                        onClick={() =>
                          voiceRecording
                            ? stopRecording("voice")
                            : startRecording("voice")
                        }
                      >
                        {voiceRecording
                          ? "Submit & Continue"
                          : "Start Answering"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showCodingChallenge && (
            <CodingExercisePage
              onSubmit={handleSubmit}
              codingChallengeId={codingChallengeId}
            />
          )}
          {showThankYouPage && <ThankYouPage />}
        </div>
      )}
    </>
  );
};

export default InterviewPage;
