import logo from "../../../images/techscreener-logo-v2.svg";
import logoIcon from "../../../images/log-in-icon-dashboardly-webflow-template.svg";

import React, { useEffect, useState } from "react";

import R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { push } from "redux-first-history";

import Button from "react-bulma-companion/lib/Button";
import Checkbox from "react-bulma-companion/lib/Checkbox";

import FormInput from "_components/elements/FormInput";

import { attemptLogin } from "_store/thunks/auth";

import useKeyPress from "_hooks/useKeyPress";

const LoginPageV2 = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [remember, setRemember] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const username = localStorage.getItem("username");
    if (username) {
      setRemember(true);
      setUsername(username);
    }
  }, []);

  const login = () => {
    const userCredentials = { username, password };

    if (remember) {
      localStorage.setItem("username", username);
    }

    setLoading(true);
    dispatch(attemptLogin(userCredentials))
      .catch(R.identity)
      .finally(() => setLoading(false));
  };

  useKeyPress("Enter", login);

  const rememberMe = () => {
    localStorage.removeItem("username");
    setRemember(!remember);
  };

  const updateUsername = (e) => setUsername(e.target.value);
  const updatePassword = (e) => setPassword(e.target.value);

  useEffect(() => {
    if (!R.isEmpty(user)) {
      dispatch(push("/roles"));
    }
  }, [dispatch, user]);

  return (
    <>
      <div className="top-bar-simple">
        <div className="container-default w-container">
          <div className="flex-horizontal">
            <a href="/" className="top-bar-simple-logo w-inline-block">
              <img src={logo} />
            </a>
          </div>
        </div>
      </div>
      <div className="dashboard-main-content utility-page">
        <div className="container-default w-container">
          <div className="module utility-page-form">
            <div className="inner-container _444px text-center">
              <div className="inner-container _80px-mbl center">
                <img
                  src={logoIcon}
                  loading="eager"
                  alt=""
                  className="mg-bottom-20px"
                />
              </div>
              <div className="heading-h3-size mg-bottom-8px">Welcome back</div>
              <div className="form min-h-188px mg-bottom-24px w-form">
                <FormInput
                  onChange={updateUsername}
                  placeholder="Username"
                  value={username}
                  // leftIcon={faUser}
                />
                <FormInput
                  onChange={updatePassword}
                  placeholder="Password"
                  value={password}
                  // leftIcon={faLock}
                  type="password"
                />
                <Button
                  className="btn-primary width-100"
                  onClick={login}
                  disabled={loading}
                >
                  Login
                </Button>
              </div>
              <div className="mg-bottom-24px">
                <div className="grid-2-columns _2-col-mbl gap-0">
                  <div className="flex align-center text-left">
                    <div className="mg-bottom-0 mg-right-8px w-form">
                      <Checkbox display="flex" alignItems="center">
                        <input
                          type="checkbox"
                          onChange={rememberMe}
                          checked={remember}
                        />
                      </Checkbox>
                    </div>
                    <div className="text-200 medium color-neutral-700">
                      Remember account
                    </div>
                  </div>
                  <a
                    href="../utility-pages/reset-password.html"
                    className="text-decoration-none color-transition-none text-right w-inline-block"
                  >
                    <div className="text-200 medium color-neutral-800">
                      Forgot your password?
                    </div>
                  </a>
                </div>
              </div>
              <div className="flex-horizontal flex-wrap">
                <div className="text-200 medium color-neutral-800">
                  Don’t have an account? 
                </div>
                <a href="../register" className="text-200">
                  Create an account
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPageV2;
