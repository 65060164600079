import React from "react";

import noRecordsImage from "../../../images/no-records-available-illustration-dashboardly-webflow-ecommerce-template.png";

const NoRecordsFound = ({ title, subTitle }) => {
  return (
    <div className="module no-records-available">
      <img
        src={noRecordsImage}
        loading="eager"
        className="no-records-available-illustration"
      />
      <div className="heading-h4-size mg-bottom-8px">{title}</div>
      <div className="text-400">{subTitle}</div>
    </div>
  );
};

export default NoRecordsFound;
