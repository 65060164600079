import React, { useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { push } from "redux-first-history";
import Title from "react-bulma-companion/lib/Title";

import { attemptUpdateJob } from "../../../../store/thunks/jobs";
import { getJob } from "_api/job";

const EditJobPage = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      skills: [{ skill: "", yearsOfExperience: "" }],
      customQuestions: [{ question: "" }],
    },
  });
  const dispatch = useDispatch();
  const { jobID } = useParams();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "skills",
  });

  const {
    fields: customQuestionFields,
    append: appendCustomQuestion,
    remove: removeCustomQuestion,
  } = useFieldArray({
    control,
    name: "customQuestions",
  });

  useEffect(() => {
    getJob(jobID)
      .then(({ job }) => {
        reset(job);
      })
      .catch((error) => {
        console.error("Error fetching job:", error);
      });
  }, [jobID, reset]);

  const onSubmit = (data) => {
    dispatch(attemptUpdateJob({ ...data, id: jobID }))
      .catch(console.error)
      .finally(() => dispatch(push("/roles")));
  };

  return (
    <div>
      <Title size="1" className="has-text-centered">
        Edit Job
      </Title>
      <div className="job-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            {...register("title", { required: "Job title is required" })}
            placeholder="Job Title"
          />
          {errors.title && <p>{errors.title.message}</p>}

          <textarea
            {...register("description")}
            placeholder="Job Description"
          />
          {errors.description && <p>{errors.description.message}</p>}

          <input {...register("jobPostingURL")} placeholder="Job Posting URL" />
          {errors.jobPostingURL && <p>{errors.jobPostingURL.message}</p>}

          {fields.map((field, index) => (
            <div key={field.id}>
              <input
                {...register(`skills.${index}.skill`, {
                  required: "Skill is required",
                })}
                placeholder="Skill"
              />
              {errors.skills && errors.skills[index]?.skill && (
                <p>{errors.skills[index].skill.message}</p>
              )}

              <Controller
                control={control}
                name={`skills.${index}.yearsOfExperience`}
                render={({ field }) => (
                  <select {...field}>
                    <option value="">Select Level Experience</option>
                    <option value="junior">Junior(1-2 years)</option>
                    <option value="mid-level">Mid Level (3-5 years)</option>
                    <option value="Senior">Senior (5+ years)</option>
                  </select>
                )}
              />
              {errors.skills && errors.skills[index]?.yearsOfExperience && (
                <p>{errors.skills[index].yearsOfExperience.message}</p>
              )}

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  className="btn-secondary w-button small"
                  type="button"
                  onClick={() => remove(index)}
                >
                  Delete Skill
                </button>
              </div>
            </div>
          ))}
          <button
            className="btn-primary w-button small"
            type="button"
            onClick={() => append({ skill: "", yearsOfExperience: "" })}
          >
            Add New Skill
          </button>

          <label>Custom Questions:</label>
          {customQuestionFields.map((field, index) => (
            <div key={field.id}>
              <input
                {...register(`customQuestions.${index}.question`, {
                  required: "Question is required",
                })}
                placeholder="Custom Question"
              />
              {errors.customQuestions &&
                errors.customQuestions[index]?.question && (
                  <p>{errors.customQuestions[index].question.message}</p>
                )}
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  type="button"
                  className="btn-secondary w-button small"
                  onClick={() => removeCustomQuestion(index)}
                >
                  Delete Question
                </button>
              </div>
            </div>
          ))}
          <button
            type="button"
            className="btn-primary w-button small"
            onClick={() => appendCustomQuestion({ question: "" })}
          >
            Add New Question
          </button>

          <button
            className="btn-primary w-button"
            style={{ width: "100%", marginTop: 10 }}
            type="submit"
          >
            Update role
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditJobPage;
