import React from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { python } from '@codemirror/lang-python'
import {java} from '@codemirror/lang-java'
import { cpp } from '@codemirror/lang-cpp'
import { rust } from '@codemirror/lang-rust'
import { csharp } from '@replit/codemirror-lang-csharp'
import { php } from '@codemirror/lang-php'
import { basicDark } from '@uiw/codemirror-theme-basic/dark';

const CodingComponent = ({value, onChange, language}) => {
  const languageSelector = {
    javascript: javascript({ jsx: true }),
    python: python(),
    java: java(),
    cpp: cpp(),
    rust: rust(),
    csharp: csharp(),
    php: php()
  }
  
    return (
    <CodeMirror 
      value={value} 
      height="600px" 
      extensions={[languageSelector[language]]} 
      onChange={onChange} 
      theme={basicDark}
    />);
};

export default CodingComponent;
