import React from 'react';
import { createRoot } from 'react-dom/client'; // Updated import

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.compat.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { history, store } from '_store';
import Root from '_components/environment/Root';

// Get the div element where the app will be mounted
const rootElement = document.getElementById('app');

// Check if the element exists
if (rootElement) {
  // Use createRoot for React 18
  const root = createRoot(rootElement);
  root.render(<Root history={history} store={store} />);
} else {
  console.error('Failed to find the root element');
}
