import React, { useState } from "react";
import { Store as RNC } from "react-notifications-component";
import { format, parseISO } from "date-fns";
import { useDispatch } from "react-redux";
import { push } from "redux-first-history";
import {
  attemptArchiveCandidate,
  attemptUpdateCandidateShortListStatus,
  attemptUpdateCandidateInactiveStatus
} from "_store/thunks/candidates";
import DeleteModal from "_components/library/DeleteModal";
import { sendInterview } from "_api/interview";
import Checkbox from "react-bulma-companion/lib/Checkbox";
import inactiveLogo from "../../../../images/inactive-candidate.svg"

const CandidateTableRow = ({
  _id,
  firstName,
  lastName,
  interviewReportId,
  stage,
  externalId,
  jobID,
  interviewReport,
}) => {
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState(false);

  const toggleShortlistStatus = () =>
    dispatch(attemptUpdateCandidateShortListStatus(_id));

  const openModal = () => setConfirm(true);
  const closeModal = () => setConfirm(false);

  const handleOnViewReportClick = () => {
    dispatch(push(`/interview_report/${interviewReportId}`));
  };

  const handleInactiveClick = () => {
    dispatch(attemptUpdateCandidateInactiveStatus(_id));
  }

  const handleOnSendInterviewClick = () => {
    sendInterview(_id)
      .then(({ message }) => {
        RNC.addNotification({
          title: "Success!",
          message: message,
          type: "success",
          container: "top-right",
          animationIn: ["animated", "fadeInRight"],
          animationOut: ["animated", "fadeOutRight"],
          dismiss: {
            duration: 5000,
          },
        });
      })
      .catch((error) => {
        console.error("Error fetching interview:", error);
      })
      .finally(() => { });
  };
  const onEditClick = () => {
    dispatch(push(`/candidate/edit/${jobID}/${_id}`));
  };

  const onDeleteClick = () => {
    dispatch(attemptArchiveCandidate(_id)) // we let the user "delete" but its really archiving
      .then((response) => {
        // Assuming your `attemptArchiveCandidate` action creator returns a Promise that resolves with a response
        RNC.addNotification({
          title: "Success",
          message: "Candidate successfully deleted",
          type: "success",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
      })
      .catch((error) => {
        // Error handling
        RNC.addNotification({
          title: "Error",
          message: "Failed to delete candidate. Please try again.",
          type: "danger",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
      });
  };

  return (
    <div className="contacts-table-row">
      <div className="table-item">
        <div className="table-item-mobile-caption">
          <div className="text-100 medium text-uppercase">Shortlist</div>
        </div>
        <div className="flex align-center">
          <div>
            <div className="mg-bottom-0 mg-right-8px w-form">
              <Checkbox
                display="flex"
                alignItems="center"
                className="mg-bottom-0"
              >
                <input
                  type="checkbox"
                  onChange={toggleShortlistStatus}
                  checked={stage === "Shortlist"}
                />
              </Checkbox>
            </div>
          </div>
          <div className="text-200 medium color-neutral-800">
            {firstName} {lastName}
            {externalId && (
              <div className="text-100">
                Id: {externalId.length > 10 ? `${externalId.substring(0, 10)}...` : externalId}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="table-item">
        <div className="table-item-mobile-caption">
          <div className="text-100 medium text-uppercase">Stage</div>
        </div>
        <div className="text-200 medium color-neutral-800">
          {stage}
        </div>
      </div>
      <div className="table-item">
        <div className="table-item-mobile-caption">
          <div className="text-100 medium text-uppercase">Hard Skills</div>
        </div>
        <div className="text-200 medium color-neutral-800">
          {interviewReport?.skillReport.map((skill) => (
            <div
              class="badge-primary small mg-bottom-6px bg-secondary-10"
              key={skill._id}
            >
              {skill.skill} : {skill.rating}
            </div>
          ))}
        </div>
      </div>
      <div className="table-item">
        <div className="table-item-mobile-caption">
          <div className="text-100 medium text-uppercase">Soft Skills</div>
        </div>
        <div className="text-200">
          {interviewReport?.softSkillsReport && (
            <div class="badge-primary small light">
              {interviewReport?.softSkillsReport.rating}
            </div>
          )}
        </div>
      </div>
      <div className="table-item">
        <div className="table-item-mobile-caption">
          <div className="text-100 medium text-uppercase">Completed date</div>
        </div>
        <div className="text-200">
          {interviewReport?.createdAt
            ? format(parseISO(interviewReport.createdAt), "MMMM d, yyyy")
            : "N/A"}
        </div>
      </div>
      <div className="table-item">
        <div className="table-item-mobile-caption">
          <div className="text-100 medium text-uppercase">
            Interview Actions
          </div>
        </div>
        <div className="text-200">
          {!interviewReportId ? (
            <button
              className="btn-secondary small w-button"
              onClick={handleOnSendInterviewClick}
            >
              Send Interview
            </button>
          ) : (
            <button
              className="btn-secondary small w-button"
              onClick={handleOnViewReportClick}
            >
              View report
            </button>
          )}
        </div>
      </div>
      <div className="table-item">
        <div className="table-item-mobile-caption">
          <div className="text-100 medium text-uppercase">
            Actions
          </div>
        </div>
        <div className="flex align-center">
          <div className="dashly-custom-icon delete-icon mg-right-8px" onClick={handleInactiveClick}>
            <img src={inactiveLogo} style={{ height: "14px", width: "14px", minHeight: "14px" }} />
          </div>
          <div
            className="dashly-custom-icon edit-icon mg-right-8px"
            onClick={onEditClick}
          >
            
          </div>
          <div className="dashly-custom-icon delete-icon" onClick={openModal}>
            
          </div>
          <DeleteModal
            active={confirm}
            onClose={closeModal}
            onDelete={onDeleteClick}
          />
        </div>
      </div>
    </div>
  );
};

export default CandidateTableRow;
