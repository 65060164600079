import { combineReducers } from "redux";

import jobs from "./jobs";
import user from "./user";
import candidates from "./candidates";

const createRootReducer = (routerReducer) =>
  combineReducers({
    router: routerReducer,
    user,
    jobs,
    candidates,
  });

export default createRootReducer;
