import React, { useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { push } from "redux-first-history";

import { attemptUpdateCandidate } from "../../../../store/thunks/candidates";
import { getCandidate } from "_api/candidates";

const EditCandidatePage = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      skills: [{ skill: "", yearsOfExperience: "" }],
    },
  });
  const dispatch = useDispatch();
  const { jobID, candidateID } = useParams();

  useEffect(() => {
    // setIsLoading(true);

    getCandidate(candidateID)
      .then(({ candidate }) => {
        reset(candidate);
      })
      .catch((error) => {
        console.error("Error fetching interview:", error);
      })
      .finally(() => {
        // setIsLoading(false);
      });
  }, [candidateID, reset]);

  const onSubmit = (data) => {
    data.id = candidateID;
    dispatch(attemptUpdateCandidate(data))
      .catch(console.error)
      .finally(() => dispatch(push(`/candidates/${jobID}`)));
  };

  return (
    <div className="job-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          {...register("firstName", { required: "First name is required" })}
          placeholder="First Name"
        />
        {errors.firstName && <p>{errors.firstName.message}</p>}

        <input
          {...register("lastName", { required: "Last name is required" })}
          placeholder="Last Name"
        />
        {errors.lastName && <p>{errors.lastName.message}</p>}

        <input
          {...register("email", { required: "Email is required" })}
          placeholder="Email"
        />
        {errors.email && <p>{errors.email.message}</p>}

        <input {...register("phone")} placeholder="Phone number" />
        {errors.phone && <p>{errors.phone.message}</p>}

        <button
          className="btn-primary w-button"
          style={{ width: "100%" }}
          type="submit"
        >
          Update Candidate
        </button>
      </form>
    </div>
  );
};

export default EditCandidatePage;
