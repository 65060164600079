import request from "superagent";

import { handleError, handleSuccess } from "_utils/api";

export const getInterview = (interviewId) =>
  request
    .get(`/api/interview/${interviewId}`)
    .then(handleSuccess)
    .catch(handleError);

export const uploadAnswer = (info) =>
  request
    .put("/api/interview/uploadAnswer")
    .send(info)
    .then(handleSuccess)
    .catch(handleError);

export const uploadCodingChallengeAnswer = (info) =>
  request
    .put("/api/interview/uploadCodingChallengeAnswer")
    .send(info)
    .then(handleSuccess)
    .catch(handleError);

export const setStart = (info) =>
  request
    .put("/api/interview/setStartInterview")
    .send(info)
    .then(handleSuccess)
    .catch(handleError);

export const setInterviewComplete = (info) =>
  request
    .put("/api/interview/setInterviewComplete")
    .send(info)
    .then(handleSuccess)
    .catch(handleError);

export const postInterview = (info) =>
  request
    .post("/api/interview")
    .send(info)
    .then(handleSuccess)
    .catch(handleError);

export const sendInterview = (candidateId) =>
  request
    .post(`/api/interview/sendInterview/${candidateId}`)
    .then(handleSuccess)
    .catch(handleError);
