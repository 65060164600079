import React from "react";
import logo from "../../../images/techscreener-logo-v2.svg";

import NavMenuList from "./NavMenuList";

const SideNavigation = () => {
  const topMenuItems = [
    {
      title: "Roles",
      logo: "",
      location: "/roles",
    },
  ];

  const bottomMenuItems = [
    {
      title: "Settings",
      logo: "",
      location: "/settings",
    },
  ];

  return (
    <div
      data-animation="over-left"
      className="sidebar-wrapper w-nav"
      data-easing2="ease"
      data-easing="ease"
      data-collapse="medium"
      data-w-id="e3b5855f-6277-057e-9411-47b56ea7154c"
      role="banner"
      data-duration="400"
      data-doc-height="1"
    >
      <div>
        <div className="sidebar-logo-section-container">
          <a href="/roles" className="sidebar-logo-wrapper w-nav-brand">
            <img src={logo} className="sidebar-logo" />
          </a>
          <div className="sidebar-logo-icon-wrapper">
            <img src="../../../images/techscreener-logo.svg" />
          </div>
          {/* WE DONT USE THIS
          <div className="sidebar-collapse-icon-container">
            <div className="sidebar-collapse-icon-inner-container">
              <div className="sidebar-collapse-icon-bar"></div>
              <div className="sidebar-collapse-icon-bar"></div>
            </div>
          </div> */}
        </div>
        <div>
          <a href="#" className="sidebar-mobile-icon w-inline-block">
            <img src="../../../images/techscreener-logo.svg" />
          </a>
          <nav role="navigation" className="sidebar-nav-menu w-nav-menu">
            <div className="sidebar-menu-wrapper">
              <div className="position-relative---z-index-1">
                <NavMenuList menuItems={topMenuItems} />
                <div className="divider _40px bg-neutral-300"></div>
                <NavMenuList menuItems={bottomMenuItems} />
              </div>
            </div>
            <div className="sidebar-menu-collapsed-overlay"></div>
          </nav>
          {/* WE DONT USE THIS
          <div className="hamburger-menu-wrapper sidebar w-nav-button">
            <div className="hamburger-menu-bar top sidebar"></div>
            <div className="hamburger-menu-bar bottom sidebar"></div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SideNavigation;
