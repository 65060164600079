export const SET_CANDIDATES_AND_TITLE = "SET_CANDIDATES_AND_TITLE";
export const ADD_CANDIDATE = "ADD_CANDIDATE";
export const ADD_INTERVIEW = "ADD_INTERVIEW";
export const UPDATE_CANDIDATE = "UPDATE_CANDIDATE";
export const DELETE_CANDIDATE = "DELETE_CANDIDATE";
export const ARCHIVE_CANDIDATE = "ARCHIVE_CANDIDATE";
export const SET_SHORTLIST_STATUS = "SET_SHORTLIST_STATUS";
export const SET_INACTIVE_STATUS = "SET_INACTIVE_STATUS";

export const setCandidatesAndTitle = (candidates, job) => ({
  type: SET_CANDIDATES_AND_TITLE,
  payload: {
    candidates,
    job,
  },
});

export const addCandidate = (candidate) => ({
  type: ADD_CANDIDATE,
  payload: {
    candidate,
  },
});

export const addInterview = (interview) => ({
  type: ADD_INTERVIEW,
  payload: {
    interview,
  },
});

export const updateCandidate = (candidate) => ({
  type: UPDATE_CANDIDATE,
  payload: {
    candidate,
  },
});

export const removeCandidate = (id) => ({
  type: DELETE_CANDIDATE,
  payload: {
    id,
  },
});

export const archiveCandidateAction = (id) => ({
  type: ARCHIVE_CANDIDATE,
  payload: {
    id,
  },
});

export const setShortListStatus = (id) => ({
  type: SET_SHORTLIST_STATUS,
  payload: {
    id,
  },
});

export const setInactiveStatus = (id) => ({
  type: SET_INACTIVE_STATUS,
  payload: {
    id,
  },
});
