import update from "immutability-helper";

import { ADD_JOB, SET_JOBS, UPDATE_JOB, REMOVE_JOB, ARCHIVE_JOB } from "_store/actions/jobs";
import { LOGOUT_USER } from "_store/actions/user";

export default function jobs(state = [], action) {
  switch (action.type) {
    case SET_JOBS:
      return update(state, { $set: action.payload.jobs });
    case ADD_JOB:
      return update(state, { $push: [action.payload.job] });
    case UPDATE_JOB:
      const updateIndex = state.findIndex(
        (job) => job._id === action.payload.job._id
      );
      if (updateIndex !== -1) {
        return update(state, {
          [updateIndex]: { $set: action.payload.job },
        });
      }
      return state;
    case REMOVE_JOB:
    case ARCHIVE_JOB:
      const deleteIndex = state.findIndex(
        (job) => job.id === action.payload.id
      );
      if (deleteIndex !== -1) {
        return update(state, { $splice: [[deleteIndex, 1]] });
      }
      return state;
    case LOGOUT_USER:
      return [];
    default:
      return state;
  }
}
