import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import InterviewPage from "../InterviewPage/InterviewPage";
import AlreadyAttemptedPage from "./AlreadyAttemptedPage";
import { getInterview, setStart } from "_api/interview";

const InterviewSetupPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [interviewQuestions, setInterviewQuestions] = useState([]);
  const [interviewId, setInterviewId] = useState("");
  const [codingChallengeId, setCodingChallengeId] = useState("");
  const [hasCodingChallenge, setHasCodingChallenge] = useState(false);
  const [hasCompleted, setHasCompleted] = useState(false);
  const { interviewID } = useParams();

  useEffect(() => {
    setIsLoading(true);

    getInterview(interviewID)
      .then(({ interview }) => {
        setInterviewQuestions(interview.questions);
        setInterviewId(interview.id);
        setHasCompleted(interview.completed);
        setHasCodingChallenge(interview.hasCodingChallenge);
        if (interview.hasCodingChallenge) {
          setCodingChallengeId(interview.codingChallenge);
        }
        setStart({ id: interview.id });
      })
      .catch((error) => {
        console.error("Error fetching interview:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <div>Loading</div>
      ) : (
        <>
          {hasCompleted ? (
            <AlreadyAttemptedPage />
          ) : (
            <InterviewPage
              interviewQuestions={interviewQuestions}
              interviewId={interviewId}
              codingChallengeId={codingChallengeId}
              hasCodingChallenge={hasCodingChallenge}
            />
          )}
        </>
      )}
    </>
  );
};

export default InterviewSetupPage;
