import React from "react";
import alertImage from "../../../images/notification-alert-illustration-dashboardly-webflow-ecommerce-template.png";

const AlreadyAttemptedPage = () => {
  return (
    <div className="module notifications-alert">
      <img
        src={alertImage}
        loading="eager"
        className="notification-alert-illustration"
      />
      <div className="heading-h4-size mg-bottom-8px">
        Seems you have already attempted this interview
      </div>
      <div className="text-400">
        Please request your recruiter to send a new interview
      </div>
    </div>
  );
};

export default AlreadyAttemptedPage;
