import React from "react";
import completedImage from "../../../images/action-completed-illustration-dashboardly-webflow-ecommerce-template.png";

const ThankYouPage = () => {
  return (
    <div className="module action-completed">
      <img
        src={completedImage}
        loading="eager"
        className="action-completed-illustration"
      />
      <div className="heading-h4-size mg-bottom-8px">
        Thank you for completing the interview!
      </div>
      <div className="text-400 mg-bottom-24px">
        We will reach out with next steps shortly.
      </div>
    </div>
  );
};

export default ThankYouPage;
