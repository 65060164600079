import React from 'react';
import NavMenuItem from '../NavMenuItem';

const NavMenuList = ({ menuItems }) => {
    return (
        <>
            {menuItems?.map((item, index) => (
                <ul key={index} role="list" className="sidebar-nav-menu-list">
                    <NavMenuItem title={item.title} dropdownList={item.dropdownList} location={item.location} logo={item.logo} />
                </ul>
            ))}
        </>
    );
};

export default NavMenuList;
