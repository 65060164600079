import React, { useEffect, useState } from "react";

const CodingChallengeAnalysis = ({
  codingChallengeAssessment,
  codingChallengeRating,
  codingChallenge,
  codingChallengeLanguage,
}) => {
  return (
    <div>
      <h4>Coding Exercise</h4>
      <p className="text-100">
        <b>Language:</b> {codingChallengeLanguage}
      </p>
      <p>
        <b>AI Assessment:</b>
        {codingChallengeAssessment}
      </p>
      <p className="text-300">
        <b>AI rating:</b> {codingChallengeRating}
      </p>
    </div>
  );
};

export default CodingChallengeAnalysis;
