import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { format, parseISO } from "date-fns";
import "react-circular-progressbar/dist/styles.css";

import greenCircle from "_images/green-circle.svg";
import redCircle from "_images/red-circle.svg";
import roleIcon from "_images/bx-briefcase.svg";
import dateIcon from "_images/bx-calendar-week.svg";
import cheatIcon from "_images/bx-check-shield.svg";
import timeIcon from "_images/bx-stopwatch.svg";

const ReportHeader = ({ candidate, createdAt, job, overAllRating }) => {
  console.log(overAllRating);
  return (
    <section className="section-base">
      <div className="leftside hero" style={{ justifyContent: "unset" }}>
        <div>
          <h2 className="h2">
            Interview Report
            <br />
            for{" "}
            <span className="text-span">
              {candidate.firstName} {candidate.lastName}
            </span>
          </h2>
        </div>
        <div className="div-wrapper">
          <div className="div-block-flex">
            <img
              src={roleIcon}
              loading="eager"
              width="24"
              height="24"
              className="image-4"
            />
            <div className="text-block">{job.title}</div>
          </div>
          <div className="div-block-flex">
            <img src={dateIcon} loading="eager" width="24" height="24" />
            <div className="text-block">
              {format(parseISO(createdAt), "MMMM d, yyyy")}
            </div>
          </div>
          {/* <div className="div-block-flex">
            <img src={timeIcon} loading="eager" width="24" height="24" />
            <div className="text-block">9 minutes and 25 seconds</div>
          </div> */}
          <div className="div-block-flex">
            <img src={cheatIcon} loading="eager" width="24" height="24" />
            <div className="text-block">No signs of unfairness detected</div>
          </div>
        </div>
      </div>
      <div className="rightside">
        <div className="div-card">
          <div>
            <div className="text-block-bold">Overall Hire Score</div>
          </div>
          <div className="div-block-3" style={{ width: 150, height: 150 }}>
            <CircularProgressbar
              value={overAllRating.rating}
              text={`${overAllRating.rating}`}
              styles={{ path: { stroke: "#1476FF" } }}
            />
          </div>
        </div>
        <div className="div-block-flex right">
          {parseInt(overAllRating.rating, 10) > 80 ? (
            <>
              <img src={greenCircle} loading="lazy" className="circle" />
              <div className="text-block-bold">Invite to Next Round</div>
            </>
          ) : (
            <>
              <img src={redCircle} loading="lazy" className="circle" />
              <div className="text-block-bold">Do Not Invite to Next Round</div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default ReportHeader;
