import React from "react";
import AlertIcon from "../../../images/alert-message-popup-icon-dashboardly-webflow-template.svg";

const ProctorModal = ({
  onClose,
  onShareScreenAndWebcam,
  isScreenSharing,
  isWebcamSharing,
}) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          zIndex: 1001,
        }}
        className="popup popup-icon-left"
      >
        <img src={AlertIcon} loading="eager" className="popup-left-icon" />
        <div>
          <div className="display-4 mg-bottom-8px">Share your screen</div>
          <div className="mg-bottom-24px">
            Share Your Screen and Webcam, it is important that you share your
            whole screen and webcam so that you do not get flagged for cheating.
          </div>
          <button
            onClick={onShareScreenAndWebcam}
            disabled={isScreenSharing}
            className="btn-secondary w-button"
          >
            {isScreenSharing && isWebcamSharing
              ? "Click here to continue"
              : "Share Screen & Webcam"}
          </button>
        </div>
        <div className="close-button-popup-module" onClick={onClose}>
          
        </div>
      </div>
    </div>
  );
};

export default ProctorModal;
