import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { push } from "redux-first-history";
import { Store as RNC } from "react-notifications-component";

import { postATS } from "_api/integrateATS";
import LoadingScreen from "../../../library/LoadingScreen";

const IntegrateServiceProvider = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);

    postATS(data)
      .then(() => {
        RNC.addNotification({
          title: "Success!",
          message: "Integration",
          type: "success",
          insert: "bottom",
          container: "top-right",
          animationIn: ["animated", "fadeInRight"],
          animationOut: ["animated", "fadeOutRight"],
          dismiss: {
            duration: 5000,
          },
        });
        dispatch(push("/roles"));
      })
      .catch((error) => {
        RNC.addNotification({
          title: "Integration failed!",
          message: "Please double check your api token",
          type: "danger",
          insert: "bottom",
          container: "top-right",
          animationIn: ["animated", "fadeInRight"],
          animationOut: ["animated", "fadeOutRight"],
          dismiss: {
            duration: 5000,
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <LoadingScreen description={"Syncing jobs"} />;
  }

  return (
    <div>
      <h1 className="has-text-centered">Integrate Service</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="job-form">
        <select
          {...register("serviceName", {
            required: "Service Provider is required",
          })}
        >
          <option value="">Select Service Provider</option>
          <option value="bamboo">Bamboo</option>
          <option value="greenhouse">Greenhouse</option>
          <option value="lever">Lever</option>
        </select>
        {errors.serviceName && <p>{errors.serviceName.message}</p>}

        <input
          {...register("apiKey", { required: "API Key is required" })}
          placeholder="API Key"
        />
        {errors.apiKey && <p>{errors.apiKey.message}</p>}

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default IntegrateServiceProvider;
