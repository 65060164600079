import update from "immutability-helper";
import R from "ramda";

import {
  ADD_CANDIDATE,
  SET_CANDIDATES_AND_TITLE,
  ADD_INTERVIEW,
  UPDATE_CANDIDATE,
  DELETE_CANDIDATE,
  ARCHIVE_CANDIDATE,
  SET_SHORTLIST_STATUS,
  SET_INACTIVE_STATUS
} from "_store/actions/candidates";
import { LOGOUT_USER } from "_store/actions/user";

export function candidate(
  state = {
    completed: false,
  },
  action
) {
  switch (action.type) {
    case ADD_CANDIDATE:
      return action.payload.candidate;
    default:
      return state;
  }
}

export default function candidates(
  state = {
    candidatesList: [],
    job: {},
  },
  action
) {
  let updateIndex = -1;
  switch (action.type) {
    case SET_CANDIDATES_AND_TITLE:
      return update(state, {
        candidatesList: { $set: action.payload.candidates },
        job: { $set: action.payload.job },
      });
    case ADD_CANDIDATE:
      return update(state, {
        candidatesList: { $push: [candidate(undefined, action)] },
      });
    case ADD_INTERVIEW:
      const candidateIndex = R.findIndex(
        R.propEq("id", action.payload.interview.candidate),
        state.candidatesList
      );
      if (candidateIndex === -1) {
        return state;
      }

      return update(state, {
        candidatesList: {
          [candidateIndex]: {
            interviews: { $push: [action.payload.interview] },
          },
        },
      });
    case UPDATE_CANDIDATE:
      updateIndex = state.candidatesList.findIndex(
        (candidate) => candidate._id === action.payload.candidate.id
      );
      if (updateIndex !== -1) {
        return update(state, {
          candidatesList: {
            [updateIndex]: { $set: action.payload.candidate },
          },
        });
      }
      return state;

    case SET_SHORTLIST_STATUS:
      updateIndex = state.candidatesList.findIndex(
        (candidate) => candidate._id === action.payload.id
      );
      if (updateIndex !== -1) {
        const currentStage = state.candidatesList[updateIndex].stage;
        let newStage;

        if (currentStage !== "Shortlist") {
          newStage = "Shortlist";
        } else if (currentStage === "Shortlist") {
          newStage = "Prospect";
        } else {
          newStage = currentStage; // backup case
        }

        return update(state, {
          candidatesList: {
            [updateIndex]: {
              stage: {
                $set: newStage,
              },
            },
          },
        });
      }

    case SET_INACTIVE_STATUS:
      updateIndex = state.candidatesList.findIndex(
        (candidate) => candidate._id === action.payload.id
      );
      if (updateIndex !== -1) {
        const currentStage = state.candidatesList[updateIndex].stage;
        let newStage;

        if (currentStage !== "Inactive") {
          newStage = "Inactive";
        } else if (currentStage === "Inactive") {
          newStage = "Prospect";
        } else {
          newStage = currentStage; // backup case
        }

        return update(state, {
          candidatesList: {
            [updateIndex]: {
              stage: {
                $set: newStage,
              },
            },
          },
        });
      }
    case DELETE_CANDIDATE:
    case ARCHIVE_CANDIDATE: // archive does the same thing effectively
      const deleteIndex = state.candidatesList.findIndex(
        (candidate) => candidate._id === action.payload.id
      );
      if (deleteIndex !== -1) {
        return update(state, {
          candidatesList: { $splice: [[deleteIndex, 1]] },
        });
      }
      return state;
    case LOGOUT_USER:
      return {
        candidatesList: [],
        jobTitle: "",
      };
    default:
      return state;
  }
}
