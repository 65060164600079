import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bulma-companion/lib/Modal';
import Card from 'react-bulma-companion/lib/Card';
import Content from 'react-bulma-companion/lib/Content';
import CardFooterItem from 'react-bulma-companion/lib/Card/CardFooterItem';

const SetShortlistStageModal = ({ active, stages, initialStage, onSave, onClose }) => {
  const [selectedStage, setSelectedStage] = useState(initialStage);

  const handleStageChange = (e) => {
    setSelectedStage(e.target.value);
  };

  const saveShortlistStage = () => {
    onSave(selectedStage);
    onClose();
  };

  return (
    <Modal className="confirm-modal" active={active}>
      <Modal.Background />
      <Modal.Content>
        <Card>
          <Card.Content>
            <Content className="has-text-centered">
              Select which stage a candidate will be advanced to in your ATS after being screened and shortlisted. Please choose carefully. This cannot be changed once selected.
              <div className="select is-fullwidth">
                <select value={selectedStage} onChange={handleStageChange}>
                  {[...stages].map(([key, value]) => (
                    <option key={key} value={`${key}: ${value[1]}`}>
                      {key}: {value[1]}
                    </option>
                  ))}
                </select>
              </div>
            </Content>
          </Card.Content>
          <Card.Footer>
            <CardFooterItem as="a" onClick={() => onClose()}>Cancel</CardFooterItem>
            <CardFooterItem as="a" onClick={saveShortlistStage}>Save</CardFooterItem>
          </Card.Footer>
        </Card>
      </Modal.Content>
      <Modal.Close size="large" aria-label="close" onClick={onClose} />
    </Modal>
  );
};

SetShortlistStageModal.propTypes = {
  active: PropTypes.bool.isRequired,
  stages: PropTypes.instanceOf(Map).isRequired, // Adjusted to expect a Map instead of an array
  initialStage: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SetShortlistStageModal;
