import React, { useEffect } from "react";

import R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { push } from "redux-first-history";

import PropTypes from "prop-types";
import SettingsMenu from "./SettingsMenu";
import ProfileSettings from "./ProfileSetting";
import AccountSettings from "./AccountSettings";

const SettingsPageV2 = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { pathname } = useLocation();
  return (
    <div className="container-default w-container">
      <div className="mg-bottom-32px">
        <h1 className="heading-h4-size mg-bottom-12px">General settings</h1>
        <div className="text-400">Update your settings here</div>
      </div>
      <div>
        <SettingsMenu pathname={pathname} />
        <Routes>
          <Route path="profile" element={<ProfileSettings />} />
          <Route path="account" element={<AccountSettings />} />
          <Route path="*" element={<ProfileSettings />} />
        </Routes>
      </div>
    </div>
  );
};

SettingsPageV2.propTypes = {};

export default SettingsPageV2;
