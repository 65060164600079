import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "redux-first-history";
import DeleteModal from "_components/library/DeleteModal";
import { attemptArchiveJob } from "../../../../store/thunks/jobs";
import { Store as RNC } from "react-notifications-component";

const JobCard = ({
  id,
  title,
  description,
  isOpen,
  createdAt,
  updatedAt,
  externalId,
  externalServiceName,
  skills,
}) => {
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState(false);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  const openModal = () => setConfirm(true);
  const closeModal = () => setConfirm(false);

  const toggleDescription = () =>
    setIsDescriptionExpanded(!isDescriptionExpanded);

  const isDescriptionLong = description?.length > 100 || false;

  const handleViewCandidatesClick = () => {
    dispatch(push(`/candidates/${id}`));
  };

  const onEditClick = () => {
    dispatch(push(`/role/edit/${id}`));
  };

  const onDeleteClick = () => {
    dispatch(attemptArchiveJob(id))
      .then((response) => {
        // Assuming your `attemptArchiveJob` action creator returns a Promise that resolves with a response
        RNC.addNotification({
          title: "Success",
          message: "Job successfully deleted", // Feedback message for successful archiving
          type: "success", // Notification type
          container: "top-right", // Position of the notification
          animationIn: ["animated", "fadeIn"], // Animation for the notification coming in
          animationOut: ["animated", "fadeOut"], // Animation for the notification going out
          dismiss: {
            duration: 3000, // Duration the notification stays on screen
            onScreen: true, // Whether the notification stays on screen upon hover
          },
        });
        setConfirm(false); // Close the modal upon successful archival
      })
      .catch((error) => {
        // Error handling
        RNC.addNotification({
          title: "Error",
          message: "Failed to delete job. Please try again.", // Error message for failing to archive
          type: "danger", // Notification type for errors
          container: "top-right", // Position of the notification
          animationIn: ["animated", "fadeIn"], // Animation for the notification coming in
          animationOut: ["animated", "fadeOut"], // Animation for the notification going out
          dismiss: {
            duration: 3000, // Duration the notification stays on screen
            onScreen: true, // Whether the notification stays on screen upon hover
          },
        });
        setConfirm(false); // Also close the modal upon failure, to allow user to retry or take other actions
      });
  };

  return (
    <div className="module projects">
      <div className="text-400 bold mg-bottom-12px">
        <span className="color-neutral-800">{title}</span>
      </div>
      <p className="mg-bottom-0">
        {isDescriptionExpanded || !isDescriptionLong
          ? description
          : `${description?.substring(0, 90)}...`}
        {isDescriptionLong && (
          <button onClick={toggleDescription}>
            {isDescriptionExpanded ? "Less" : "More"}
          </button>
        )}
      </p>
      {externalId && (
        <p className="text-50">Imported from {externalServiceName}</p>
      )}
      <div className="divider _32px"></div>
      <h5>Skills:</h5>
      {skills.map((skill) => (
        <p key={skill._id}>
          <b>{skill.skill}: </b> {skill.yearsOfExperience}
        </p>
      ))}
      <div className="divider top-32px---bottom-24px"></div>
      <div className="grid-2-columns _2-col-mbl _1-5fr---1fr">
        <button
          onClick={handleViewCandidatesClick}
          className="btn-secondary small w-button"
        >
          View Candidates
        </button>
        <div className="flex align-center">
          <div
            className="dashly-custom-icon edit-icon mg-right-8px"
            onClick={onEditClick}
          >
            
          </div>
          <div className="dashly-custom-icon delete-icon" onClick={openModal}>
            
          </div>
          <DeleteModal
            active={confirm}
            onClose={closeModal}
            onDelete={onDeleteClick}
          />
        </div>
      </div>
    </div>
  );
};

export default JobCard;
