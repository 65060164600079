import {
  postCandidate,
  getCandidates,
  deleteCandidate,
  archiveCandidate,
  putCandidate,
  putShortListStatus,
  putInactiveStatus
} from "_api/candidates";
import { postInterview } from "_api/interview";

import {
  addCandidate,
  setCandidatesAndTitle,
  addInterview,
  removeCandidate,
  archiveCandidateAction,
  updateCandidate,
  setShortListStatus,
  setInactiveStatus
} from "_store/actions/candidates";

import { dispatchError } from "_utils/api";

export const attemptAddCandidate = (newCandidate) => (dispatch) =>
  postCandidate(newCandidate)
    .then(({ candidate, user }) => {
      dispatch(addCandidate(candidate));
      return user;
    })
    .catch(dispatchError(dispatch));

export const attemptGetCandidates =
  (jobID, params = "") =>
    (dispatch) =>
      getCandidates(jobID, params)
        .then(({ candidates, job }) => {
          console.log(candidates, job);
          dispatch(setCandidatesAndTitle(candidates, job));
          return { candidates, job };
        })
        .catch(dispatchError(dispatch));

export const attemptAddInterview = (info) => (dispatch) =>
  postInterview(info)
    .then(({ interview }) => {
      dispatch(addInterview(interview));
      return interview;
    })
    .catch(dispatchError(dispatch));

export const attemptUpdateCandidate = (candidate) => (dispatch) =>
  putCandidate(candidate)
    .then(({ candidate }) => {
      dispatch(updateCandidate(candidate));
      return { candidate };
    })
    .catch(dispatchError(dispatch));

export const attemptDeleteCandidate = (id) => (dispatch) =>
  deleteCandidate({ id })
    .then((data) => {
      dispatch(removeCandidate(id));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptArchiveCandidate = (id) => (dispatch) =>
  archiveCandidate({ id })
    .then((data) => {
      dispatch(archiveCandidateAction(id));
      return Promise.resolve(data); // Explicitly return a resolved promise with the data
    })
    .catch((error) => {
      dispatchError(dispatch)(error);
      return Promise.reject(error); // Explicitly return a rejected promise with the error
    });

export const attemptUpdateCandidateShortListStatus = (id) => (dispatch) =>
  putShortListStatus(id)
    .then(({ candidate }) => {
      console.log(id);
      dispatch(setShortListStatus(id));
      return { candidate };
    })
    .catch(dispatchError(dispatch));

export const attemptUpdateCandidateInactiveStatus = (id) => (dispatch) =>
  putInactiveStatus(id)
    .then(({ candidate }) => {
      console.log(id);
      dispatch(setInactiveStatus(id));
      return { candidate };
    })
    .catch(dispatchError(dispatch));
