import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const SkillAnalysis = ({
  skill,
  analysis,
  yearsOfExperience,
  rating,
  rankRating,
}) => {
  return (
    <div className="feedback-card card inline">
      <div className="div-block-5">
        <div>
          <div className="label">Technical Skill</div>
          <h4 className="h4">{skill}</h4>
        </div>
        <div className="div-block-6" style={{ width: 100, height: 100 }}>
          <CircularProgressbar
            value={rankRating}
            text={`${rankRating}`}
            styles={{ path: { stroke: "#1476FF" } }}
          />
        </div>
      </div>
      <div>
        <div className="text-block c">Feedback</div>
        <p className="paragraph-2">{analysis}</p>
      </div>
    </div>
  );
};

export default SkillAnalysis;
