import request from "superagent";

import { handleError, handleSuccess } from "_utils/api";

export const postATS = (info) =>
  request
    .post("/api/serviceConfig")
    .send(info)
    .then(handleSuccess)
    .catch(handleError);

export const importCandidates = (info) =>
  request
    .post("/api/serviceConfig/import-candidates")
    .send(info)
    .then(handleSuccess)
    .catch(handleError);

export const updateATSCandidates = (info) =>
  request
    .put("/api/serviceConfig/update-ats-candidates")
    .send(info)
    .then(handleSuccess)
    .catch(handleError);
