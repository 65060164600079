import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getInterviewReport } from "_api/interviewReport";
import SkillAnalysis from "./SkillAnalysis";
import CodingChallengeAnalysis from "./CodingChallengeAnalysis";
import SoftSkillsAnalysis from "./SoftSkillsAnalysis";
import ReportHeader from "./ReportHeader";

const InterviewReportPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [interviewReport, setInterviewReport] = useState(null);
  const { interviewID } = useParams();

  useEffect(() => {
    setIsLoading(true);

    getInterviewReport(interviewID)
      .then((response) => {
        setInterviewReport(response.interviewReport);
      })
      .catch((error) => {
        console.error("Error fetching interview:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [interviewID]);

  return (
    <div>
      {isLoading ? (
        <div>Loading</div>
      ) : (
        <div>
          <ReportHeader {...interviewReport} />
          <div className="section-base flex">
            {interviewReport &&
              interviewReport.skillReport.map((skillAnalysis) => (
                <SkillAnalysis key={skillAnalysis._id} {...skillAnalysis} />
              ))}
            {interviewReport.softSkillsReport && (
              <>
                <SoftSkillsAnalysis
                  analysis={interviewReport.softSkillsReport.analysis}
                  rating={interviewReport.softSkillsReport.rating}
                />
              </>
            )}
          </div>
          {interviewReport.codingChallenge && (
            <>
              <div className="divider _40px bg-neutral-300" />
              <CodingChallengeAnalysis {...interviewReport} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default InterviewReportPage;
