import React from "react";
import ReactLoading from "react-loading";

const LoadingScreen = ({ description }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh", // 100% of the viewport height
      textAlign: "center", // Center the text for smaller screens or if the description wraps
    }}
  >
    <ReactLoading type="spin" color="#3898EC" height={375} width={375} />
    <h1>{description}</h1>
  </div>
);

export default LoadingScreen;
