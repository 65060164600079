import request from "superagent";

import { handleError, handleSuccess } from "_utils/api";

export const postJob = (info) =>
  request.post("/api/job").send(info).then(handleSuccess).catch(handleError);

export const getJobs = () =>
  request.get("/api/job").then(handleSuccess).catch(handleError);

export const getJob = (id) =>
  request.get(`/api/job/${id}`).then(handleSuccess).catch(handleError);

export const deleteJob = (info) =>
  request.delete("/api/job").send(info).then(handleSuccess).catch(handleError);

export const archiveJob = (info) =>
  request.put("/api/job/archive").send(info).then(handleSuccess).catch(handleError);

export const putJob = (info) =>
  request.put("/api/job").send(info).then(handleSuccess).catch(handleError);

export const setJobShortlistStage = (info) => {
  request.put(`/api/job/set-shortlist-stage`).send(info).then(handleSuccess).catch(handleError);
}
