import React from 'react';

const Examples = ({ examples }) => (
    <div className="examples-container">
      {examples.map((example, index) => (
        <div key={index} className="example">
          <div><strong>Input:</strong> {example.input}</div>
          <div><strong>Output:</strong> {example.output}</div>
          <div><strong>Explanation:</strong> {example.explanation}</div>
        </div>
      ))}
    </div>
);

export default Examples;