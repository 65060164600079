import { Store as RNC } from "react-notifications-component";
import { push } from "redux-first-history";

import { postLogin, postLogout, postRegister } from "_api/auth";

import { login, logout } from "_store/actions/user";

import { dispatchError } from "_utils/api";

export const attemptLogin = (user) => (dispatch) =>
  postLogin(user)
    .then((data) => {
      dispatch(login(data.user));

      RNC.addNotification({
        title: "Success!",
        message: data.message,
        type: "success",
        container: "top-right",
        animationIn: ["animated", "fadeInRight"],
        animationOut: ["animated", "fadeOutRight"],
        dismiss: {
          duration: 5000,
        },
      });

      dispatch(push("/roles"));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptRegister = (newUser) => (dispatch) =>
  postRegister(newUser)
    .then((data) => {
      RNC.addNotification({
        title: "Success!",
        message: data.message,
        type: "success",
        container: "top-right",
        animationIn: ["animated", "fadeInRight"],
        animationOut: ["animated", "fadeOutRight"],
        dismiss: {
          duration: 5000,
        },
      });

      return dispatch(attemptLogin(newUser));
    })
    .then(() => dispatch(push("/settings")))
    .catch(dispatchError(dispatch));

export const attemptLogout = () => (dispatch) =>
  postLogout()
    .then((data) => {
      dispatch(logout());

      RNC.addNotification({
        title: "Success!",
        message: data.message,
        type: "success",
        container: "top-right",
        animationIn: ["animated", "fadeInRight"],
        animationOut: ["animated", "fadeOutRight"],
        dismiss: {
          duration: 5000,
        },
      });

      dispatch(push("/login"));
      return data;
    })
    .catch(dispatchError(dispatch));
