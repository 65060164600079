import { postJob, getJobs, deleteJob, putJob, archiveJob } from "_api/job";

import { addJob, setJobs, updateJob, removeJob, archiveJobAction } from "_store/actions/jobs";

import { dispatchError } from "_utils/api";

export const attemptAddJob = (newJob) => (dispatch) =>
  postJob(newJob)
    .then(({ job, user }) => {
      dispatch(addJob(job));
      return user;
    })
    .catch(dispatchError(dispatch));

export const attemptGetJobs = () => (dispatch) =>
  getJobs()
    .then(({ jobs }) => {
      dispatch(setJobs(jobs));
      return jobs;
    })
    .catch(dispatchError(dispatch));

export const attemptUpdateJob = (job) => (dispatch) =>
  putJob(job)
    .then((updatedJob) => {
      dispatch(updateJob(updatedJob));
      return updatedJob;
    })
    .catch(dispatchError(dispatch));

export const attemptRemoveJob = (id) => (dispatch) =>
  deleteJob({ id })
    .then(() => {
      dispatch(removeJob(id));
    })
    .catch(dispatchError(dispatch));

export const attemptArchiveJob = (id) => (dispatch) =>
  archiveJob({ id })
    .then(() => {
      dispatch(archiveJobAction(id));
      return Promise.resolve(); // Explicitly return a resolved promise
    })
    .catch((error) => {
      dispatchError(dispatch)(error);
      return Promise.reject(error); // Explicitly return a rejected promise with the error
    });

