import request from 'superagent';
import axios from 'axios';

import { handleError, handleSuccess } from '_utils/api';

export const uploadToS3 = (info) => 
    request.post('/api/aws/getSignedUrl').send(info)
    .then((response) => {
        const { signedUrl, key } = JSON.parse(response.text);

        axios.put(signedUrl, info.blob, {
            headers: {
                'Content-Type': info.contentType,
            },
        }).then(handleSuccess)
        .catch(handleError)
    })
    .catch(handleError);