import React from 'react';

const Selector = ({ selectedValue, onSelectValue, options, className }) => (
    <select
      className={className}
      value={selectedValue}
      onChange={(e) => onSelectValue(e.target.value)}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
  
export default Selector;