import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const SoftSkillsAnalysis = ({ analysis, rating }) => {
  const skillMap = {
    "not-passionate": 0,
    indifferent: 50,
    "very-passionate": 90,
  };
  return (
    <div className="feedback-card card inline">
      <div className="div-block-5">
        <div>
          <div className="label">Soft skills results</div>
        </div>
        <div className="div-block-6" style={{ width: 100, height: 100 }}>
          <CircularProgressbar
            value={rating}
            text={`${rating}`}
            styles={{ path: { stroke: "#1476FF" } }}
          />
        </div>
      </div>
      <div>
        <div className="text-block c">Feedback</div>
        <p className="paragraph-2">{analysis}</p>
      </div>
    </div>
  );
};

export default SoftSkillsAnalysis;
