import React, { useEffect, useState } from "react";

import R from "ramda";
import { ReactNotifications } from "react-notifications-component";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";

import Footer from "_components/layouts/Footer";
import HomePage from "_components/pages/HomePage";
import LostPage from "_components/pages/LostPage";
import InterviewSetupPage from "../../pages/InterviewPage/InterviewSetupPage";
import JobPage from "_components/pages/JobPage";
import CreateJobPage from "../../pages/JobPage/CreateJob/CreateJobPage";
import CandidatePage from "_components/pages/CandidatePage";
import InterviewReportPage from "_components/pages/InterviewReportPage";
import SideNavigation from "../../layouts/SideNavigation";
import AddCandidate from "../../pages/CandidatePage/AddCandidate";
import RegisterPageV2 from "../../pages/RegisterPagev2";
import LoginPageV2 from "../../pages/LoginPageV2";
import SettingsPageV2 from "../../pages/SettingsPageV2";
import IntegrateServiceProvider from "../../pages/JobPage/IntegrateServiceProvider";

import { attemptGetUser } from "_store/thunks/user";

import NavBar from "../../layouts/NavBar";
import EditCandidatePage from "../../pages/CandidatePage/EditCandidatePage";
import EditJobPage from "../../pages/JobPage/EditJobPage";
import AiInterview from "../../pages/AiInterview";
import VoiceRecorder from "../../pages/AiInterviewSocket";

export default function Main() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();
  const isAuthenticated = useSelector((state) => !R.isEmpty(state.user));

  useEffect(() => {
    dispatch(attemptGetUser())
      .then(() => setLoading(false))
      .catch(R.identity)
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    !loading && (
      <React.Fragment>
        <ReactNotifications />
        {isAuthenticated && <SideNavigation />}
        <div className={`${isAuthenticated ? "dashboard-main" : ""}`}>
          <div className={`${isAuthenticated ? "sidebar-spacer" : ""}`}></div>
          <div className={`${isAuthenticated ? "dashboard-content" : ""}`}>
            {isAuthenticated && <NavBar />}
            <div
              className={`${isAuthenticated ? "dashboard-main-content" : ""}`}
            >
              <div
                className={`${
                  isAuthenticated ? "container-default w-container" : ""
                }`}
              >
                <Routes>
                  <Route path="/" element={<JobPage />} />
                  <Route
                    path="/interview/:interviewID"
                    element={<InterviewSetupPage />}
                  />
                  <Route
                    path="/interview_report/:interviewID"
                    element={<InterviewReportPage />}
                  />
                  <Route path="login" element={<LoginPageV2 />} />
                  <Route path="register" element={<RegisterPageV2 />} />
                  <Route path="home" element={<HomePage />} />
                  <Route path="roles" element={<JobPage />} />
                  <Route path="role/add" element={<CreateJobPage />} />
                  <Route path="role/edit/:jobID" element={<EditJobPage />} />
                  <Route path="candidates/:jobID" element={<CandidatePage />} />
                  <Route
                    path="integrateService"
                    element={<IntegrateServiceProvider />}
                  />
                  <Route
                    path="candidate/edit/:jobID/:candidateID"
                    element={<EditCandidatePage />}
                  />
                  <Route path="aiInterview" element={<VoiceRecorder />} />
                  <Route
                    path="candidate/add/:jobID"
                    element={<AddCandidate />}
                  />
                  <Route path="settings/*" element={<SettingsPageV2 />} />
                  <Route path="*" element={<LostPage />} />
                </Routes>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </React.Fragment>
    )
  );
}
