import React from 'react';

const Requirements = ({ requirements }) => (
    <div className="requirements">
        {requirements.map((req, index) => (
        <div key={index} className="requirement">{req}</div>
        ))}
    </div>
);

export default Requirements;