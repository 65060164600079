import React, { useState, useEffect } from "react";

export default function VoiceRecorder() {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Initialize WebSocket connection
    const ws = new WebSocket("wss://183e-173-64-119-46.ngrok-free.app");
    setSocket(ws);

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((stream) => {
        const options = { mimeType: "audio/webm" }; // Commonly supported format
        const mediaRecorder = new MediaRecorder(stream, options);
        mediaRecorder.start(3000);

        mediaRecorder.ondataavailable = (e) => {
          if (
            socket &&
            socket.readyState === WebSocket.OPEN &&
            e.data.size > 0
          ) {
            socket.send(e.data);
            // socket.send(JSON.stringify({ action: "start" }));
          }
        };

        // Optional: Stop recording after a certain time
        setTimeout(() => {
          mediaRecorder.stop();
        }, 30000); // for example, 10 seconds
      })
      .catch((error) => {
        console.error("Error accessing the microphone:", error);
      });
  };

  const run = () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      // socket.send(e.data);
      const testBuffer = new Uint8Array([72, 101, 108, 108, 111]); // Represents the string "Hello" in ASCII
      socket.send(testBuffer.buffer);
    }
  };

  const run1 = () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify({ test: "Hello, server!" }));
      //   console.log(`Sending message type: ${typeof message}`, message);
      //   socket.send(message);
    }
  };
  return (
    <div>
      <button onClick={startRecording}>Start Recording</button>
      <button onClick={run1}>Start stream</button>
    </div>
  );
}
