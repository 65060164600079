import React, { useState, useEffect } from 'react';
import Container from 'react-bulma-companion/lib/Container';
import Title from 'react-bulma-companion/lib/Title';

import CodingComponent from './CodingComponent';
import { getCodingChallenge } from '_api/codingChallenge';
import ExerciseDescription from './ExerciseDescription';
import Examples from './Examples';
import Requirements from './Requirements';
import Selector from '../../elements/Selector';
import Button from '../../elements/Button';
import Timer from '../../elements/Timer/Timer';

import './CodingExercisePage.css';

const CodingExercisePage = ({codingChallengeId, onSubmit}) => {
  const [code, setCode] = useState('// Write your code here');
  const [timeLeft, setTimeLeft] = useState('15:00');
  const [language, setLanguage] = useState('javascript');
  const [codingChallenge, setCodingChallenge] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [startTime, setStartTime] = useState(Date.now());

  const languageOptions = [
    { value: 'javascript', label: 'JavaScript' },
    { value: 'python', label: 'Python' },
    { value: 'java', label: 'Java' },
    { value: 'cpp', label: 'C++' },
    { value: 'rust', label: 'Rust' },
    { value: 'csharp', label: 'C#' },
    { value: 'php', label: 'PHP' }
  ];

  // Convert timeLeft to seconds for the countdown
  const timeInSeconds = () => {
    const [minutes, seconds] = timeLeft.split(':').map(Number);
    return minutes * 60 + seconds;
  };

  useEffect(() => {
    setIsLoading(true);

    getCodingChallenge(codingChallengeId)
      .then(({ codingChallenge }) => {
        setCodingChallenge(codingChallenge);
      })
      .catch((error) => {
        console.error('Error fetching codingChallenge:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleSubmit = (timeExpired = false) => {
    const timeTakenInSeconds = (Date.now() - startTime) / 1000;
    const codingChallengeLanguage = language; // Assuming 'language' holds the selected language
    onSubmit(code, codingChallengeLanguage, timeTakenInSeconds, timeExpired);
  };

  useEffect(() => {
    if (timeInSeconds() > 0) {
      const timer = setInterval(() => {
        const totalSeconds = timeInSeconds() - 1;
        const minutes = Math.floor(totalSeconds / 60).toString().padStart(2, '0');
        const seconds = (totalSeconds % 60).toString().padStart(2, '0');
        setTimeLeft(`${minutes}:${seconds}`);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      handleSubmit(true);
    }
  }, [timeLeft]);

    const onChange = React.useCallback((val, viewUpdate) => {
        setCode(val);
    }, []);

  return (
    <div>
      {isLoading?
        <Container>
          <Title size="1" textAlign="center">
            Loading
          </Title>
        </Container> :
      <div className="coding-exercise-page">
        <div className="left-panel">
          <ExerciseDescription text={codingChallenge.description} />
          <Examples examples={codingChallenge.examples} />
          <Requirements requirements={codingChallenge.restrictions} />
        </div>
        <div className="right-panel">
          <div className="controls-row">
            <Selector
              selectedValue={language}
              onSelectValue={setLanguage}
              options={languageOptions}
              className="language-selector"
            />
            <Timer timeLeft={timeLeft} />
          </div>
          <CodingComponent value={code} onChange={onChange} language={language}/>
          <div className="bottom-panel">
            <Button onClick={() => handleSubmit(false)} color="success" label="Submit" />
          </div>
        </div>
      </div>
      }
    </div>
  );
};

export default CodingExercisePage;
