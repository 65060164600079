import request from "superagent";

import { handleError, handleSuccess } from "_utils/api";

export const postCandidate = (info) =>
  request
    .post("/api/candidate")
    .send(info)
    .then(handleSuccess)
    .catch(handleError);

export const getCandidate = (candidateID) =>
  request
    .get(`/api/candidate/${candidateID}`)
    .then(handleSuccess)
    .catch(handleError);

export const getCandidates = (jobID, params = "") =>
  request
    .get(`/api/candidate/job/${jobID}?${params}`)
    .then(handleSuccess)
    .catch(handleError);

export const deleteCandidate = (info) =>
  request
    .delete("/api/candidate")
    .send(info)
    .then(handleSuccess)
    .catch(handleError);

export const archiveCandidate = (info) =>
  request
    .put("/api/candidate/archive")
    .send(info)
    .then(handleSuccess)
    .catch(handleError);

export const putCandidate = (info) =>
  request
    .put("/api/candidate")
    .send(info)
    .then(handleSuccess)
    .catch(handleError);

export const putShortListStatus = (info) =>
  request
    .put("/api/candidate/updateCandidateShortlist")
    .send({ id: info })
    .then(handleSuccess)
    .catch(handleError);

export const putInactiveStatus = (info) =>
  request
    .put("/api/candidate/updateCandidateInactive")
    .send({ id: info })
    .then(handleSuccess)
    .catch(handleError);
