import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bulma-companion/lib/Modal';
import Card from 'react-bulma-companion/lib/Card';
import Content from 'react-bulma-companion/lib/Content';
import CardFooterItem from 'react-bulma-companion/lib/Card/CardFooterItem';
import { saveFeedback } from '../../../api/feedback';

const FeedbackFormModal = ({ active, onClose, pageName, user }) => {
  const [feedback, setFeedback] = useState({
    name: `${user.firstName} ${user.lastName}` || "",
    email: user.username,
    pageName: pageName, // Use passed pageName as initial value
    title: '',
    description: '',
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!feedback.email.includes('@')) {
      newErrors.email = "Invalid email address";
    }
    if (feedback.name.trim().length === 0) {
      newErrors.name = "Name is required";
    }
    if (feedback.title.trim().length === 0) {
      newErrors.title = "Title is Required"
    }
    if (feedback.description.trim().length === 0) {
      newErrors.description = "Title is Required"
    }
    // Add other validation checks as needed
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveFeedback = () => {
    if (!validateForm()) {
      return; // Prevent form submission if validation fails
    }
    saveFeedback(feedback)
      .then(() => {
        onClose(); // Close modal on success
        RNC.addNotification({
          title: "Feedback Sent!",
          message: "Thanks!",
          type: "success",
          insert: "bottom",
          container: "top-right",
          animationIn: ["animated", "fadeInRight"],
          animationOut: ["animated", "fadeOutRight"],
          dismiss: {
            duration: 3000,
          },
        });
      })
      .catch(error => {
        console.log(error);
      });
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedback(prevFeedback => ({
      ...prevFeedback,
      [name]: value,
    }));
  };

  return (
    <Modal className="feedback-modal" active={active}>
      <Modal.Background />
      <Modal.Content>
        <Card>
          <Card.Content>
            <Content>
              <p className="has-text-centered"> Please fill out the form below for any issues. Your feedback is important to us. Thanks! </p>
              <div className="field">
                <label className="label">Name</label>
                <div className="control">
                  <input className="input" type="text" name="name" value={feedback.name} onChange={handleChange} placeholder="Your name" />
                  {errors.name && <p className="help is-danger">{errors.name}</p>}
                </div>
              </div>

              <div className="field">
                <label className="label">Email</label>
                <div className="control">
                  <input className="input" type="email" name="email" value={feedback.email} onChange={handleChange} placeholder="Your email" />
                  {errors.email && <p className="help is-danger">{errors.email}</p>}
                </div>
              </div>

              <div className="field">
                <label className="label">Page</label>
                <div className="control">
                  <input className="input" type="text" name="pageName" value={feedback.pageName} onChange={handleChange} placeholder="Related page name" />
                  {errors.pageName && <p className="help is-danger">{errors.pageName}</p>}
                </div>
              </div>

              <div className="field">
                <label className="label">Feedback Summary</label>
                <div className="control">
                  <input className="input" type="text" name="title" value={feedback.title} onChange={handleChange} placeholder="Short title" />
                  {errors.title && <p className="help is-danger">{errors.title}</p>}
                </div>
              </div>

              <div className="field">
                <label className="label">Description</label>
                <div className="control">
                  <textarea
                    className="textarea"
                    name="description"
                    value={feedback.description}
                    onChange={handleChange}
                    placeholder="Describe your feedback"
                    style={{ height: '200px', overflowY: 'auto' }}></textarea>
                  {errors.description && <p className="help is-danger">{errors.description}</p>}
                </div>
              </div>
            </Content>
          </Card.Content>
          <Card.Footer>
            <CardFooterItem as="a" onClick={onClose}>Cancel</CardFooterItem>
            <CardFooterItem as="a" onClick={handleSaveFeedback}>Save</CardFooterItem>
          </Card.Footer>
        </Card>
      </Modal.Content>
      <Modal.Close size="large" aria-label="close" onClick={onClose} />
    </Modal>
  );
};

FeedbackFormModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  pageName: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
};

export default FeedbackFormModal;
