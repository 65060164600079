export const SET_JOBS = "SET_JOB";
export const ADD_JOB = "ADD_JOB";
export const UPDATE_JOB = "UPDATE_JOB";
export const REMOVE_JOB = "REMOVE_JOB";
export const ARCHIVE_JOB = "ARCHIVE_JOB";

export const setJobs = (jobs) => ({
  type: SET_JOBS,
  payload: {
    jobs,
  },
});

export const addJob = (job) => ({
  type: ADD_JOB,
  payload: {
    job,
  },
});

export const updateJob = (job) => ({
  type: UPDATE_JOB,
  payload: {
    job,
  },
});

export const removeJob = (id) => ({
  type: REMOVE_JOB,
  payload: {
    id,
  },
});

export const archiveJobAction = (id) => ({
  type: ARCHIVE_JOB,
  payload: {
    id,
  },
});
