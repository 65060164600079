import React, { useEffect } from 'react';

import R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';

import Container from 'react-bulma-companion/lib/Container';
import Section from 'react-bulma-companion/lib/Section';
import Title from 'react-bulma-companion/lib/Title';
import SideNavigation from '../../layouts/SideNavigation';

export default function HomePage() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  useEffect(() => {
    if (R.isEmpty(user)) {
      dispatch(push('/login'));
    }
  }, [dispatch, user]);

  return (
    <div data-w-id="d94b3df1-c1b1-4948-b755-c6382cce95e8" className="page-wrapper">
      <SideNavigation/>
    </div>
  );
}
