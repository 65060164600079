import React, { useCallback, useEffect, useState } from "react";

import R from "ramda";
import { useDispatch, useSelector } from "react-redux";

import { faRotate } from "@fortawesome/free-solid-svg-icons/faRotate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Box from "react-bulma-companion/lib/Box";
import Button from "react-bulma-companion/lib/Button";
import Column from "react-bulma-companion/lib/Column";
import Columns from "react-bulma-companion/lib/Columns";
import Control from "react-bulma-companion/lib/Control";
import Field from "react-bulma-companion/lib/Field";
import Help from "react-bulma-companion/lib/Help";
import Icon from "react-bulma-companion/lib/Icon";
import Image from "react-bulma-companion/lib/Image";
import Input from "react-bulma-companion/lib/Input";
import Label from "react-bulma-companion/lib/Label";
import Textarea from "react-bulma-companion/lib/Textarea";

import { attemptGetUser, attemptUpdateUser } from "_store/thunks/user";

import { validateName } from "_utils/validation";

const ProfileSettings = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [firstName, setFirstName] = useState(user.firstName || "");
  const [lastName, setLastName] = useState(user.lastName || "");
  const [bio, setBio] = useState(user.bio || "");
  const [profilePic, setProfilePic] = useState(user.profilePic || "");
  const [firstNameEdited, setFirstNameEdited] = useState(false);
  const [lastNameEdited, setLastNameEdited] = useState(false);
  const [bioEdited, setBioEdited] = useState(false);
  const [profilePicEdited, setProfilePicEdited] = useState(false);

  const resetState = useCallback(() => {
    setFirstName(user.firstName || "");
    setLastName(user.lastName || "");
    setBio(user.bio || "");
    setProfilePic(user.profilePic || "");
    setFirstNameEdited(false);
    setLastNameEdited(false);
    setBioEdited(false);
    setProfilePicEdited(false);
  }, [user]);

  useEffect(() => {
    resetState();
  }, [user, resetState]);

  const updateFirstName = (e) => {
    if (validateName(e.target.value)) {
      setFirstName(e.target.value);
      setFirstNameEdited(true);
    }
  };

  const updateLastName = (e) => {
    if (validateName(e.target.value)) {
      setLastName(e.target.value);
      setLastNameEdited(true);
    }
  };

  const updateBio = (e) => {
    setBio(e.target.value);
    setBioEdited(true);
  };

  const updateProfilePic = (e) => {
    setProfilePic(e.target.value);
    setProfilePicEdited(true);
  };

  const refresh = () =>
    dispatch(attemptGetUser()).then(resetState).catch(R.identity);

  const save = () => {
    const updatedUser = {};

    if (firstNameEdited) {
      updatedUser.firstName = firstName;
    }
    if (lastNameEdited) {
      updatedUser.lastName = lastName;
    }
    if (profilePicEdited) {
      updatedUser.profilePic = profilePic;
    }
    if (bioEdited) {
      updatedUser.bio = bio;
    }

    if (!R.isEmpty(updatedUser)) {
      dispatch(attemptUpdateUser(updatedUser)).catch(R.identity);
    }
  };

  const charactersRemaining = 240 - bio.length;
  const edited =
    firstNameEdited || lastNameEdited || bioEdited || profilePicEdited;

  return (
    <div data-w-tab="Tab 1" className="w-tab-pane w--tab-active">
      <div className="module account">
        <div className="heading-h4-size">Account</div>
        <div className="divider top-40px---bottom-18px"></div>
        <div className="grid-2-columns _1-3fr---1fr">
          <div className="flex align-center">
            {/* <img
              loading="eager"
              src="../images/john-carter-avatar-medium-dashboardly-webflow-template.jpg"
              className="avatar-circle _04 mg-right-12px"
            /> */}
            <div>
              <div className="heading-h5-size mg-bottom-6px">
                {user.firstName} {user.lastName}
              </div>
            </div>
          </div>
          <div
            id="w-node-_364f5dd5-3363-3fb7-aa7b-dcd2d903cefa-4fbdfd46"
            data-w-id="364f5dd5-3363-3fb7-aa7b-dcd2d903cefa"
            className="display-inline-block"
          >
            {/* <a href="#" className="btn-secondary small w-button">
              Upload new
            </a> */}
            <div className="popup-wrapper">
              <div className="popup simple-popup">
                <div className="display-4 mg-bottom-8px">
                  Do you want to upload a new profile picture?
                </div>
                <div className="mg-bottom-24px">
                  Sit in tincidunt semper vestibulum semper etiam sedol elit id
                  in fermentum quam blandit quis amet.
                </div>
                <div className="grid-2-columns gap-18px _2-columns-mbl">
                  <a
                    id="w-node-_364f5dd5-3363-3fb7-aa7b-dcd2d903cf04-4fbdfd46"
                    data-w-id="364f5dd5-3363-3fb7-aa7b-dcd2d903cf04"
                    href="#"
                    className="btn-secondary small w-button"
                  >
                    Cancel
                  </a>
                  <a
                    id="w-node-_364f5dd5-3363-3fb7-aa7b-dcd2d903cf06-4fbdfd46"
                    href="#"
                    className="btn-primary small w-button"
                  >
                    Continue
                  </a>
                </div>
                <div
                  data-w-id="364f5dd5-3363-3fb7-aa7b-dcd2d903cf08"
                  className="close-button-popup-module"
                >
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divider top-28px---bottom-36px"></div>
        <div className="mg-bottom-32px">
          <div className="text-400 bold mg-bottom-12px">
            <span className="color-neutral-800">Basic information</span>
          </div>
        </div>
        <div className="form w-form">
          <div className="w-layout-grid grid-2-columns form">
            <div>
              <label className="text-200 mg-bottom-10px color-neutral-700">
                Fist name
              </label>
              <Control>
                <Input
                  id="first-name"
                  placeholder={user.firstName || "Last Name"}
                  value={firstName}
                  onChange={updateFirstName}
                />
              </Control>
            </div>
            <div>
              <label className="text-200 mg-bottom-10px color-neutral-700">
                Last name
              </label>
              <Control>
                <Input
                  id="last-name"
                  placeholder={user.lastName || "Last Name"}
                  value={lastName}
                  onChange={updateLastName}
                />
              </Control>
            </div>
            <div id="w-node-f668b2c7-fa5c-21d0-b1f1-d60daa6008b8-4fbdfd46">
              <label className="text-200 mg-bottom-10px color-neutral-700">
                About yourself
              </label>
              <Control>
                <Textarea
                  id="bio"
                  placeholder={user.bio || "Tell us about yourself."}
                  className="text-area w-input"
                  value={bio}
                  maxLength={240}
                  onChange={updateBio}
                />
              </Control>
              <Help>{`Characters remaining: ${charactersRemaining}`}</Help>
            </div>
          </div>
        </div>
        <div
          data-w-id="111bb980-3e4c-df81-fb84-b1e70f33c826"
          className="display-inline-block"
        >
          <button
            className="btn-primary w-button"
            onClick={save}
            disabled={!edited}
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
