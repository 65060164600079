import React from 'react';
import R from 'ramda';
import { useSelector } from 'react-redux';

import JobCard from '../JobCard';

const JobCardList = ({ searchTerm }) => {
    const jobs = useSelector(state => state.jobs);

    const filteredJobs = jobs.filter(job => job.title.toLowerCase().includes(searchTerm.toLowerCase()));

    return (
        <div className="grid-3-columns">
            {R.reverse(filteredJobs).map(job => <JobCard key={job.id} {...job} />)}
        </div>
    );
};

export default JobCardList;
