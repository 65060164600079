import React, { useState } from "react";

import R from "ramda";
import { useDispatch } from "react-redux";

import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons/faTriangleExclamation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Control from "react-bulma-companion/lib/Control";
import Field from "react-bulma-companion/lib/Field";
import Help from "react-bulma-companion/lib/Help";
import Icon from "react-bulma-companion/lib/Icon";
import Input from "react-bulma-companion/lib/Input";

import { postCheckUsername } from "_api/users";

import { attemptRegister } from "_store/thunks/auth";

import useKeyPress from "_hooks/useKeyPress";

import { validatePassword, validateUsername } from "_utils/validation";

const validateCompanyName = (companyName) => {
  return companyName.trim() !== "";
};

import rImage from "../../../images/sign-up-illustration-dashboardly-webflow-template.svg";
import logo from "../../../images/techscreener-logo-v2.svg";

const RegisterPageV2 = () => {
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [usernameMessage, setUsernameMessage] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");
  const [companyNameMessage, setCompanyNameMessage] = useState("");

  const [usernameAvailable, setUsernameAvailable] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [companyNameValid, setCompanyNameValid] = useState(false);

  const [loading, setLoading] = useState(false);

  const checkPassword = (newUsername, newPassword) => {
    const { valid, message } = validatePassword(newUsername, newPassword);

    setPasswordValid(valid);
    setPasswordMessage(message);
  };

  const checkUsername = (newUsername) => {
    const { valid, message } = validateUsername(newUsername);

    if (valid) {
      setUsernameMessage("Checking username...");
      setUsernameAvailable(false);

      postCheckUsername(newUsername)
        .then((res) => {
          setUsernameAvailable(res.available);
          setUsernameMessage(res.message);
        })
        .catch(R.identity);
    } else {
      setUsernameAvailable(valid);
      setUsernameMessage(message);
    }
  };

  const updateUsername = (newUserName) => {
    setUsername(newUserName);
    checkPassword(newUserName, password);
  };

  const handleUsernameChange = (e) => {
    updateUsername(e.target.value);
    checkUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    checkPassword(username, e.target.value);
  };

  const handleCompanyNameChange = (e) => {
    const newCompanyName = e.target.value;
    setCompanyName(newCompanyName);
    setCompanyNameValid(validateCompanyName(newCompanyName));
    setCompanyNameMessage(
      validateCompanyName(newCompanyName) ? "" : "Company name is required"
    );
  };

  const register = () => {
    if (usernameAvailable && passwordValid && companyNameValid) {
      const newUser = {
        username,
        password,
        companyName,
      };

      setLoading(true);
      dispatch(attemptRegister(newUser))
        .catch(R.identity)
        .finally(() => setLoading(false));
    }
  };

  useKeyPress("Enter", register);

  return (
    <div className="sign-up-page-main-container">
      <div className="sign-up-page-left-side">
        <div className="sign-up-page-logo-container">
          <div className="inner-container _434px width-100">
            <a href="/roles" className="sign-up-page-logo w-inline-block">
              <img src={logo} />
            </a>
          </div>
        </div>
        <div className="inner-container _434px width-100 _100-mbl">
          <h1 className="heading-h3-size mg-bottom-8px">Create an account</h1>
          <p className="mg-bottom-20px">
            Already have an account? <a href="/login">Log in</a>
          </p>
          <div className="form min-h-256px mg-bottom-24px w-form">
            <Field>
              <Control iconsRight>
                <Input
                  id="username"
                  placeholder="Username"
                  color={
                    username
                      ? usernameAvailable
                        ? "success"
                        : "danger"
                      : undefined
                  }
                  value={username}
                  onChange={handleUsernameChange}
                />
                {username && (
                  <Icon
                    size="small"
                    align="right"
                    color={usernameAvailable ? "success" : "danger"}
                  >
                    <FontAwesomeIcon
                      icon={usernameAvailable ? faCheck : faTriangleExclamation}
                    />
                  </Icon>
                )}
              </Control>
              {username && (
                <Help color={usernameAvailable ? "success" : "danger"}>
                  {usernameMessage}
                </Help>
              )}
            </Field>
            <Field>
              <Control iconsRight>
                <Input
                  id="companyName"
                  placeholder="Company Name"
                  color={companyNameValid ? "success" : "danger"}
                  value={companyName}
                  onChange={handleCompanyNameChange}
                />
                {companyName && (
                  <Icon
                    size="small"
                    align="right"
                    color={companyNameValid ? "success" : "danger"}
                  >
                    <FontAwesomeIcon
                      icon={companyNameValid ? faCheck : faTriangleExclamation}
                    />
                  </Icon>
                )}
              </Control>
              {companyName && (
                <Help color={companyNameValid ? "success" : "danger"}>
                  {companyNameMessage}
                </Help>
              )}
            </Field>
            <Field>
              <Control iconsRight>
                <Input
                  id="password"
                  placeholder="Password"
                  type="password"
                  color={
                    password
                      ? passwordValid
                        ? "success"
                        : "danger"
                      : undefined
                  }
                  value={password}
                  onChange={handlePasswordChange}
                />
                {password && (
                  <Icon
                    size="small"
                    align="right"
                    color={passwordValid ? "success" : "danger"}
                  >
                    <FontAwesomeIcon
                      icon={passwordValid ? faCheck : faTriangleExclamation}
                    />
                  </Icon>
                )}
              </Control>
              {password && (
                <Help color={passwordValid ? "success" : "danger"}>
                  {passwordMessage}
                </Help>
              )}
            </Field>
            <button
              className="btn-primary width-100 w-button"
              disabled={!passwordValid || !usernameAvailable || loading}
              onClick={register}
            >
              Create Account
            </button>
          </div>
          {/* <div className="flex align-center mg-bottom-32px">
            <div className="mg-bottom-0 mg-right-8px w-form">
              <input type="checkbox" id="checkbox-3" name="checkbox-3" data-name="Checkbox 3" checked=""/>
            </div>
            <div className="text-200 medium color-neutral-700">Accept terms and conditions</div>
          </div> */}
        </div>
      </div>
      <div className="sign-up-page-right-side">
        <div className="inner-container _478px width-100">
          <img
            src={rImage}
            loading="eager"
            alt="Sign Up - Dashly X Webflow Template"
            className="width-100"
          />
        </div>
        <div className="sign-up-page-right-side-bg"></div>
      </div>
    </div>
  );
};

export default RegisterPageV2;
