import React, { useEffect, useState } from "react";

import R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { push } from "redux-first-history";

import { attemptGetJobs } from "_store/thunks/jobs";

import JobCardList from "./JobCardList";
import NoRecordsFound from "../../elements/NoRecordsFound";
import AddJobOptionsModal from "./AddJobOptionsModal";

export default function JobPage() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const jobs = useSelector((state) => state.jobs);

  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // New state for search term

  useEffect(() => {
    if (R.isEmpty(user)) {
      dispatch(push("/login"));
    } else {
      setLoading(true);

      dispatch(attemptGetJobs())
        .catch(R.identity)
        .finally(() => setLoading(false));
    }
  }, [dispatch, user]);

  const handleImportRoles = () => {
    dispatch(push("/integrateService"));
  };

  const handleAddRole = () => {
    dispatch(push("/role/add"));
  };
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    !loading && (
      <div>
        {showModal && (
          <AddJobOptionsModal
            onClose={closeModal}
            handleImportRoles={handleImportRoles}
            handleAddRole={handleAddRole}
          />
        )}
        <div className="container-default w-container">
          <div className="mg-bottom-40px">
            <div className="grid-2-columns _2-col-mbl _1-col-mbp">
              <div>
                <h1 className="heading-h4-size mg-bottom-8px">Roles</h1>
              </div>
              <a
                className="btn-primary w-button"
                onClick={() => setShowModal(true)}
              >
                <span className="line-rounded-icon link-icon-left"></span>
                Add Role
              </a>
            </div>
          </div>
          <div style={{ position: 'relative', width: "35%", marginBottom: "15px" }}>
            <input
              type="text"
              placeholder="Search by role name..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="input dashboard-top-search-bar"
            />
            <span className="line-rounded-icon dashboard-top-search-bar-icon"></span>
          </div>
          {jobs.length == 0 ? (
            <NoRecordsFound
              title={"No Roles found"}
              subTitle={"Please click add role to get started"}
            />
          ) : (
            <JobCardList searchTerm={searchTerm} />
          )}
        </div>
      </div>
    )
  );
}
