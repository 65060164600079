import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CandidateTableRow from "./CandidateTableRow";
import ShortlistInfoModal from "../ShortlistInfoModal";

const CandidateTable = ({ jobID, shortlistStage }) => {
  const candidates = useSelector((state) => state.candidates.candidatesList);

  const [showShortlistInfo, setShowShortlistInfo] = useState(false);

  return (
    <div className="overflow-visible w-tab-content">
      {shortlistStage && <ShortlistInfoModal
        active={showShortlistInfo}
        shortlistStage={shortlistStage}
        onClose={() => setShowShortlistInfo(false)}
      />}

      <div className="w-tab-pane w--tab-active">
        <div className="module border-radius-30px overflow-hidden">
          <div className="contacts-table-row header">
            <div className="flex align-center">
              <div className="flex align-center cursor-pointer">
                <div className="text-100 medium text-uppercase" style={{ marginRight: '3px' }} >Shortlist</div>
                {shortlistStage && <div className="dashly-custom-icon dashboard-nav-profile-icon" onClick={() => setShowShortlistInfo(true)}>
                  
                </div>}
              </div>
            </div>
            <div className="flex align-center cursor-pointer">
              <div className="text-100 medium text-uppercase">Stage</div>
            </div>
            <div className="flex align-center cursor-pointer">
              <div className="text-100 medium text-uppercase">Hard Skills</div>
            </div>
            <div className="flex align-center cursor-pointer">
              <div className="text-100 medium text-uppercase">Soft Skills</div>
            </div>
            <div className="flex align-center cursor-pointer">
              <div className="text-100 medium text-uppercase">
                Completed date
              </div>
            </div>
            <div className="flex align-center cursor-pointer">
              <div className="text-100 medium text-uppercase">
                Interview Actions
              </div>
            </div>
            <div className="flex align-center cursor-pointer">
              <div className="text-100 medium text-uppercase">
                Actions
              </div>
            </div>
          </div>
          {candidates.length === 0 ? (
            <div className="text-center text-400 medium" style={{ marginTop: "20px", marginBottom: "20px" }}>
              There are no candidates available to screen for this Job.
            </div>
          ) : (
            candidates.map((candidate) => (
              <CandidateTableRow
                key={candidate._id}
                {...candidate}
                jobID={jobID}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default CandidateTable;
