import React from "react";
import timerIcon from "../../../images//timer.png";

const Timer = ({ timeLeft }) => (
  <div className="timer">
    <img src={timerIcon} style={{ maxHeight: "16px" }} />
    {timeLeft}
  </div>
);

export default Timer;
