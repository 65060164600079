import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';

const NavMenuItem = ({title, location, dropdownList, logo}) => {
    const dispatch = useDispatch();
    const handleOnClick = () => {
        dispatch(push(location));
    }

    return (
        <li className="sidebar-nav-list-item">
            <div className="dropdown-wrapper sidebar-dropdown w-dropdown" onClick={handleOnClick}>
                <div className="dropdown-toggle sidebar-dropdown w-dropdown-toggle">
                    <div className="sidebar-dropdown-text-container">
                        <div className="dashly-custom-icon sidebar-link-icon">{logo}</div>
                        <div className="sidebar-dropdown-text">{title}</div>
                    </div>
                    <div className="line-rounded-icon sidebar-dropdown-arrow"></div>
                </div>
                {/* TODO: DROP down item for each nav item */}
                {/* {isDropdownOpen && (
                    <nav className="dropdown-column-wrapper sidebar-dropdown w-dropdown-list">
                        <div className="sidebar-dropdown-inner-wrapper">
                            <div>
                                {dropdownList?.map(item => (
                                    <a key={item.key} href={item.location} className="dropdown-link sidebar-link w-dropdown-link">{item.title}</a>
                                ))}
                            </div>
                        </div>
                    </nav>
                )} */}
            </div>
        </li>
    );
};

export default NavMenuItem;
