import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bulma-companion/lib/Modal';
import Card from 'react-bulma-companion/lib/Card';
import Content from 'react-bulma-companion/lib/Content';
import CardFooterItem from 'react-bulma-companion/lib/Card/CardFooterItem';

const ShortlistInfoModal = ({ active, shortlistStage, onClose }) => {
  return (
    <Modal className="shortlist-info-modal" active={active}>
      <Modal.Background />
      <Modal.Content>
        <Card>
          <Card.Content>
            <Content className="has-text-centered">
              The shortlist stage you have selected for this position is: {shortlistStage}.
            </Content>
          </Card.Content>
          <Card.Footer>
            <CardFooterItem as="a" onClick={onClose}>OK</CardFooterItem>
          </Card.Footer>
        </Card>
      </Modal.Content>
      <Modal.Close size="large" aria-label="close" onClick={onClose} />
    </Modal>
  );
};

ShortlistInfoModal.propTypes = {
  active: PropTypes.bool.isRequired,
  shortlistStage: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default ShortlistInfoModal;
