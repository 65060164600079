import React from "react";
import AlertIcon from "../../../../images/alert-message-popup-icon-dashboardly-webflow-template.svg";

const AddCandidateOptionsModal = ({
  onClose,
  handleAddCandidate,
  handleImportCandidates,
}) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          zIndex: 1001,
        }}
        className="popup popup-icon-left"
      >
        <img src={AlertIcon} loading="eager" className="popup-left-icon" />
        <div>
          <div className="display-4 mg-bottom-8px">
            How do you want to add your roles?
          </div>
          <button
            onClick={handleImportCandidates}
            className="btn-primary w-button mg-right-6px"
          >
            Import from ATS
          </button>
          <button
            onClick={handleAddCandidate}
            className="btn-secondary w-button"
          >
            Enter Manually
          </button>
        </div>
        <div className="close-button-popup-module" onClick={onClose}>
          
        </div>
      </div>
    </div>
  );
};

export default AddCandidateOptionsModal;
