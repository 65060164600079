import React from "react";

import { Link, useLocation } from "react-router-dom";

import PropTypes from "prop-types";

const SettingsMenu = (props) => {
  const { pathname } = useLocation();
  return (
    <div className="settings-v1-tab-menu w-tab-menu">
      <a
        href="/settings/profile"
        className={`settings-v1-tab-link w-inline-block w-tab-link ${
          pathname.includes("profile") ||
          pathname === "/settings" ||
          pathname === "/settings/"
            ? "w--current"
            : ""
        }`}
      >
        <div className="dashly-custom-icon"></div>
        <div>Profile</div>
      </a>
      <a
        href="/settings/account"
        className={`settings-v1-tab-link w-inline-block w-tab-link ${
          pathname.includes("account") ? "w--current" : ""
        }`}
      >
        <div className="dashly-custom-icon"></div>
        <div>Account</div>
      </a>
    </div>
  );
};

SettingsMenu.propTypes = {};

export default SettingsMenu;
