import React from "react";

export default function FooterComponent() {
  const year = new Date().getFullYear();

  return (
    <div className="dashboard-footer-wrapper">
      <div className="container-default w-container">
        <div className="dashboard-footer-inner-wrapper">
          <div>{`Copyright Ⓒ ${year} PreScreener. All Rights Reserved.`}</div>
        </div>
      </div>
    </div>
  );
}
