import React, { useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import R from "ramda";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { push } from "redux-first-history";
import { attemptAddCandidate } from "../../../../store/thunks/candidates";
import LoadingScreen from "../../../library/LoadingScreen";

const AddCandidate = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const dispatch = useDispatch();
  const { jobID } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  // Email validation pattern
  const emailPattern = {
    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    message: "Invalid email format",
  };

  // Phone number validation pattern (simple example)
  const phonePattern = {
    value: /^\d{10}$/,
    message: "Invalid phone number, must be 10 digits",
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    data.job = jobID;
    dispatch(attemptAddCandidate(data))
      .catch(R.identity)
      .finally(() => {
        setIsLoading(false);
        dispatch(push(`/candidates/${jobID}`));
      });
  };

  if (isLoading) {
    return <LoadingScreen description={"Adding candidate"} />;
  }

  return (
    <div className="job-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          {...register("firstName", { required: "First name is required" })}
          placeholder="First Name"
        />
        {errors.firstName && <p>{errors.firstName.message}</p>}

        <input
          {...register("lastName", { required: "Last name is required" })}
          placeholder="Last Name"
        />
        {errors.lastName && <p>{errors.lastName.message}</p>}

        <input
          {...register("email", {
            required: "Email is required",
            pattern: emailPattern,
          })}
          placeholder="Email"
        />
        {errors.email && <p>{errors.email.message}</p>}

        <input
          {...register("phone", { pattern: phonePattern })}
          placeholder="Phone number"
        />
        {errors.phone && <p>{errors.phone.message}</p>}
        <button
          className="btn-primary w-button"
          style={{ width: "100%" }}
          type="submit"
        >
          Add Candidate
        </button>
      </form>
    </div>
  );
};

export default AddCandidate;
